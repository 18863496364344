import React, { useEffect } from 'react';
import { Switch, Redirect, Route, useLocation, useHistory } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import SignUp from './Auth/SignUp';
import Login from './Auth/Login';
import Sidebar from './SideBar';
import AdminDashboard from './Admin';
import PendingUsersPage from './pendingUsers';
import SystemAdmin from './SystemAdmin';
import ForgotPassword from './Auth/ForgotPassword';
import ResetPasswordSuccess from './Auth/ResetPasswordSucess';
import SetNewPassword from './Auth/SetNewPassword';
import ResetSuccess from './Auth/Reset';

const App = () => {
  const history = useHistory();
  const location = useLocation();

  // Effect to store the last path visited
  useEffect(() => {
    sessionStorage.setItem('lastPath', location.pathname);
  }, [location]);

  // Effect to redirect to the last visited path on initial load
  useEffect(() => {
    const lastPath = sessionStorage.getItem('lastPath');
    if (lastPath && lastPath !== location.pathname) {
      history.push(lastPath);
    }
  }, [history]);

  return (
    <div className='bg-gray-100' style={{ backgroundColor: '#010808' }}>
      <Switch>
        <Redirect exact from="/" to="/login" />
        <Route path="/signup" component={SignUp} />
        <Route path="/login" component={Login} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/reset-password-success" component={ResetPasswordSuccess} />
        <Route path="/set-new-password/:resetToken" component={SetNewPassword} />
        <Route path="/reset-success" component={ResetSuccess} />
        <Route path="/admin-dashboard" component={AdminDashboard} />
        <Route path="/admin" component={SystemAdmin} />
        <Route path="/pending" component={PendingUsersPage} />
        <Route path="/sidebar" component={Sidebar} />
      </Switch>
                                                     
    </div>
  );
}

export default App;
