import React, { useState, useEffect, useRef } from 'react';
import { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useSwipeable } from 'react-swipeable';
import EControlUnitForm from './eControlUnit';
import axios from 'axios';
import './styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import Chart from 'chart.js/auto';
import icon from '../Assets/iconS.svg';
import bookingd from '../Assets/bookingd.svg';
import action from '../Assets/Actiond.svg';
import connect from '../Assets/connectd.svg';
import device from '../Assets/Deviced.svg';
import event from '../Assets/events.svg';
import clock from '../Assets/clock.svg';
import alert from '../Assets/alert.svg';
import alertdevice from '../Assets/AlertT.svg';
import NewBookingForm from './NewBooking';
import ConnectionForm from './AddConnection';

const Dashboard = ({setIsMenuOpen}) => {
  const history = useHistory();
  const [showModalDevice, setShowModalDevice] = useState(false);
  const [showModalBooking, setShowModalBooking] = useState(false);
  const [showModalConnection, setShowModalConnection] = useState(false);
  const [rows, setRows] = useState([]);
  const [totalDevices, setTotalDevices] = useState(0);
  const [totalConnections, setTotalConnections] = useState(0);
  const [activeBookingsCount, setActiveBookingsCount] = useState(0);
  const [bookingsNext24HoursCount, setBookingsNext24HoursCount] = useState(0);
  const [totalSuccess, setTotalSuccess] = useState(0);
  const [totalFailed, setTotalFailed] = useState(0);
  const [othersCount, setTotalOthersCount] = useState(0);
  const [soonCount, setTotalSoonCount] = useState(0);
  const [expiredCount, setTotalExpiredCount] = useState(0);
  const [selectedMenuItem, setSelectedMenuItem] = useState('All');
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);
  const stableIsSmallScreen = useMemo(() => isSmallScreen, []); 
  const [totalActions, setTotalActions] = useState(0);
  const [eventsLast24Hours, setEventsLast24Hours] = useState(0);
  const [bookings, setBookings] = useState([]);
  const [userData, setUserData] = useState(null);

  const storedRole = localStorage.getItem('role');
  const storedCompany = localStorage.getItem('company');

  useEffect(() => {
    const userDataFromStorage = localStorage.getItem('userData');
    if (userDataFromStorage) {
      const parsedUserData = JSON.parse(userDataFromStorage);
      setUserData(parsedUserData);
    }
  }, []); 

  const chartRef = useRef(null);

  useEffect(() => {
    fetchEventData();
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, []);

  const fetchActionData = async () => {
    try {
        const response = await axios.get('https://eunitstest.onrender.com/api/getdevice');
        console.log('Response:', response);
        if (response.status === 200) {
            let devices = response.data.filter(device => device.category === 'Control');

            if (storedRole === "Company User" && storedCompany) {
                devices = devices.filter(device => device.company === storedCompany);
            }

            if (storedRole === "Company Admin" && storedCompany) {
              devices = devices.filter(device => device.company === storedCompany);
            }

            const total = devices.length;
            setTotalActions(total);
        } else {
            console.error('Failed to fetch device data. Status:', response.status);
        }
    } catch (error) {
        console.error('Error fetching device data:', error);
    }
  };

  const toggleModalDevice = () => {
    setShowModalDevice(!showModalDevice);
  };
  
  const toggleModalBooking = () => {
    setShowModalBooking(!showModalBooking);
  };

  const toggleModalConnection = () => {
    setShowModalConnection(!showModalConnection);
  };

  const handleCodeClick = () => {
    history.push('/admin/booking')
  }

  const handleCodeClickNext = () => {
    history.push('/admin/booking?selectedTab=next');
  };

  const handleConnectionClick = () => {
    history.push('/admin/connections')
  }

  const handleDeviceClick = () => {
    history.push('/admin/devices')
  }

  const handleActionClick = () => {
    history.push('/admin/actions')
  }

  const handleEventLast24Hours = () => {
    history.push('/admin/events?filter=Last24Hours');
  };
  
  const handleEventFailClick = () => {
    history.push('/admin/events?filter=Fail');
  };
  
  const handleAddConnection = async (newConnectionData) => {
    try {
      const response = await axios.post('https://eunitstest.onrender.com/api/addconnectiondetails', newConnectionData);

      if (response.status === 200) {
        const newDeviceData = response.data.result;
        console.log(newDeviceData);
        const updatedRows = [newDeviceData, ...rows];
        setRows(updatedRows);
      } else {
        console.error('Failed to add connection:', response.data.error);
      }
    } catch (error) {
      console.error('Error adding connection:', error);
    }
  };

  const fetchDeviceData = async () => {
    try {
        const response = await axios.get('https://eunitstest.onrender.com/api/getdevice');
        console.log('Response:', response);
        if (response.status === 200) {
            let devices = response.data;
            if (storedRole === "Company Admin" && storedCompany) {
                devices = devices.filter(device => device.company === storedCompany);
            }
            if (storedRole === "Company User" && storedCompany) {
              devices = devices.filter(device => device.company === storedCompany);
            }
            const total = devices.length;
            setTotalDevices(total);
        } else {
            console.error('Failed to fetch device data. Status:', response.status);
        }
    } catch (error) {
        console.error('Error fetching device data:', error);
    }
  };

  const fetchConnectionData = async () => {
    try {
      const response = await axios.get('https://eunitstest.onrender.com/api/getconnectiondetails');
      if (response.status === 200) {
        const connections = response.data;
        const total = connections.length;
        setTotalConnections(total);
      } else {
        console.error('Failed to fetch connection data. Status:', response.status);
      }
    } catch (error) {
      console.error('Error fetching connection data:', error);
    }
  };

  const fetchBookingData = async () => {
    try {
        const response = await axios.get('https://eunitstest.onrender.com/api/getbooking');
        if (response.status === 200) {
            let bookingsData = response.data;

            if (storedRole === "Company Admin") {
                bookingsData = bookingsData.filter(booking => booking.company === storedCompany);
            }
            if (storedRole === "Company User") {
              bookingsData = bookingsData.filter(booking => booking.company === storedCompany);
            }

            const now_ms = Date.now();
            const convertToUnixTimestamp = (time, date) => {
              const dateTimeString = `${date} ${time}`;
              const dateTime = new Date(dateTimeString);
              const unixTimestampInSeconds = Math.floor(dateTime.getTime() / 1000);
              return unixTimestampInSeconds;
            };

            const activeBookings = bookingsData.filter(booking => {
              const startsAtTimestamp = convertToUnixTimestamp(booking.bookingStartsAtTime, booking.bookingStartDate);
              const endsAtTimestamp = convertToUnixTimestamp(booking.bookingEndsAtTime, booking.bookingEndDate);
              const tolerance = 10 * 60;
              return (now_ms >= (startsAtTimestamp * 1000 - tolerance)) && (now_ms <= (endsAtTimestamp * 1000 + tolerance));
            });

            setActiveBookingsCount(activeBookings.length);

            const twentyFourHoursFromNow = now_ms + (24 * 60 * 60 * 1000);
            const bookingsNext24Hours = bookingsData.filter(booking => {
                const startsAtTimestamp = convertToUnixTimestamp(booking.bookingStartsAtTime, booking.bookingStartDate);
                return startsAtTimestamp * 1000 > now_ms && startsAtTimestamp * 1000 <= twentyFourHoursFromNow;
            });

            setBookingsNext24HoursCount(bookingsNext24Hours.length);
        } else {
            console.error('Failed to fetch booking data. Status:', response.status);
        }
    } catch (error) {
        console.error('Error fetching booking data:', error);
    }
  };

  const fetchEventData = async () => {
    try {
        const response = await axios.get('https://eunitstest.onrender.com/api/geteventdetails');
        if (response.status === 200) {
            let eventData = response.data;
            console.log('Initial event data:', eventData); 

            if (["Company User", "Company Admin"].includes(storedRole)) {
              eventData = eventData.filter(event => {
                  const companyMatch = event.company_name;
                  console.log(`Company match: ${companyMatch}`);
                  return companyMatch;
              });
              console.log(`Filtered event data for role ${storedRole}:`, eventData);
          }

            const now = Date.now();
            const last24HoursEvents = eventData.filter(event => {
              const eventTime = new Date(event.timestamp).getTime();
              return now - eventTime <= 24 * 60 * 60 * 1000;
            });

            const successCount = eventData.reduce((acc, event) => {
              return ['True', 'true', 'success', 'Success'].includes(event.status) ? acc + 1 : acc;
            }, 0);

            const failedCount = eventData.filter(event => ['fail', 'Fail', 'False', 'false', null, 'connection'].includes(event.status)).length;
            const othersCount = eventData.length - successCount - failedCount;
            const expiredCount = eventData.filter(event => ['Expired', 'expired'].includes(event.status)).length;
            const soonCount = eventData.filter(event => event.status === 'Soon').length;

             if (["Company User", "Company Admin"].includes(storedRole)) {
                  setEventsLast24Hours(Math.floor(last24HoursEvents.length / 2 - 1.5));
              } else {
                  setEventsLast24Hours(last24HoursEvents.length);
              }
            setTotalSuccess(successCount);
            setTotalFailed(failedCount);
            setTotalOthersCount(othersCount);
            setTotalExpiredCount(expiredCount);
            setTotalSoonCount(soonCount);
        } else {
            console.error('Failed to fetch event data. Status:', response.status);
        }
    } catch (error) {
        console.error('Error fetching event data:', error);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

    const chartId = isSmallScreen ? 'eventPieChartSmall' : 'eventPieChartLarge';
    const setupChart = () => {
        const ctx = document.getElementById(chartId);
        if (!ctx) return;

        if (chartRef.current) {
            chartRef.current.destroy();
        }

        chartRef.current = new Chart(ctx, {
            type: 'pie',
            data: {
                labels: ['Success', 'Failed', 'Others'],
                datasets: [{
                    data: [totalSuccess, totalFailed, othersCount],
                    backgroundColor: ['#32D583', '#F05D38', '#FFA500'],
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        position: 'right',
                    },
                }
            }
        });
    };

    window.requestAnimationFrame(() => {
        setupChart();
    });

  const handlers = useSwipeable({
    onSwipedLeft: () => setIsMenuOpen(false),
    onSwipedRight: () => setIsMenuOpen(true),
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
    trackMouse: false
  });

  const BookingsContent = () => (
    <div className='flex flex-col md:flex-row gap-6 items pt-2'>
            <div className='flex flex-row gap-2 md:items-center md:justify-center'>
              <img
                src={bookingd}
                alt="User"
                className="w-6 h-6"
              />
              <span className='text-lg font-semibold' style={{ lineHeight: '24px', fontSize: '16px', color: '#010808' }}>Bookings</span>
            </div>

            <div className='flex flex-col md:flex-row gap-4 items-center justify-center'>
              <div className="p-4 rounded shadow-md" style={{ backgroundColor: '#FEF0C7',cursor: 'pointer', textDecoration: 'none', width:'290px'}} onClick={handleCodeClick}>
                <div className='flex flex-row justify-between gap-6 ' style={{ lineHeight: '24px', fontSize: '16px', color: '#010808', cursor: 'pointer', textDecoration: 'none' }}>
                  <h2 className="text-left text-lg font-semibold mb-6" style={{ lineHeight: '24px', fontSize: '16px', color: '#010808' }}>Active bookings</h2>
                        <img
                          src={alert}
                          alt="User"
                          className="w-6 h-6"
                        />
                </div>
                  <p className="text-lg font-semibold mb-2" style={{ lineHeight: '44px', fontSize: '36px', color: '#010808' }}>{activeBookingsCount}</p>
              </div>

              <div className="bg-white p-4 rounded shadow-md" style={{ cursor: 'pointer', textDecoration: 'none', width:'290px'}} onClick={handleCodeClickNext}>
                <h2 className="text-lg font-semibold mb-6" style={{ lineHeight: '24px', fontSize: '16px', color: '#010808' }} >Next 24 hours</h2>
                <p className="text-lg font-semibold mb-2" style={{ lineHeight: '44px', fontSize: '36px', color: '#010808' }}>{bookingsNext24HoursCount}</p>
              </div>

              <div className="bg-white p-4 flex items-center justify-initial md:justify-center md:p-0 rounded shadow-md" style={{ lineHeight: '24px', fontSize: '16px', color: '#010808', width:'290px', height:'140px' }}>
                <button 
                  onClick={toggleModalBooking}
                  className="text-lg font-semibold mb-2"
                >
                  New Booking
                </button>
              </div>
            </div>
            <div className="w-full border-b-2 mb-2 mt-2 border-gray-100"></div>
        </div>
  );

  const EventsContent = () => (
    <div className='flex flex-col md:flex-row gap-3 md:gap-52'>
            <div className='flex flex-row gap-2 md:items-center md:justify-center'>
              <img
                src={event}
                alt="User"
                className="w-6 h-6 mt-5"
              />
              <h2 className='text-lg font-semibold' style={{ lineHeight: '24px', fontSize: '16px', color: '#010808' }}>Events</h2>
            </div>

            <div className='flex flex-col md:flex-row gap-4 items-center justify-center'>
            <div className="bg-white p-4 rounded shadow-md" style={{ cursor: 'pointer', textDecoration: 'none', width:'290px'}} onClick={handleEventLast24Hours}>
                <div className='flex flex-row justify-between gap-8'>
                <h2 className="font-semibold mb-6" style={{ lineHeight: '24px', fontSize: '16px', color: '#010808' }}>Last 24 hours</h2>
                </div>
                  <p className="text-lg font-semibold mb-2" style={{ lineHeight: '44px', fontSize: '36px', color: '#32D583' }}>{eventsLast24Hours}</p>
              </div>

              <div className="bg-white p-4 rounded shadow-md" style={{ cursor: 'pointer', textDecoration: 'none', width:'290px'}} onClick={handleEventFailClick}>
                <h2 className="text-lg font-semibold mb-6" style={{ lineHeight: '24px', fontSize: '16px', color: '#010808' }}>Total Failed Event</h2>
                <p className="text-lg font-semibold mb-2 text-red-500" style={{ lineHeight: '44px', fontSize: '36px'}}>{totalFailed}</p>
              </div>

              <div className="bg-white flex items-center justify-center rounded shadow-md px-4">
              <canvas id="eventPieChartSmall" style={{ width:'260px', height: 'auto' }}></canvas>
              </div>
            </div>
            <div className="w-full border-b-2 mb-2 mt-2 border-gray-100"></div>
          </div>
  );

  const ConnectionsContent = () => {
    if (userData && userData.role === "Company Admin") {
      return null; 
    }

    if (userData && userData.role === "Company User") {
      return null; 
    }
  
    return (
      <div className='flex flex-col md:flex-row gap-3 md:gap-40'>
            <div className='flex flex-row gap-2 md:items-center md:justify-center'>
              <img
                src={connect}
                alt="User"
                className="w-6 h-6 mt-4 ml-2"
              />
              <h2 className='text-lg font-semibold' style={{ lineHeight: '24px', fontSize: '16px', color: '#010808' }}>Connections</h2>
            </div>

            <div className='flex flex-col md:flex-row gap-2 items-center justify-center'>
              <div className="bg-white p-4 rounded shadow-md" style={{ cursor: 'pointer', textDecoration: 'none', width:'290px'}} onClick={handleConnectionClick}>
                <div className='flex flex-row justify-between gap-8'>
                <h2 className="font-semibold mb-6" style={{ lineHeight: '24px', fontSize: '16px', color: '#010808' }}>Active Connection</h2>
                  <img
                    src={clock}
                    alt="User"
                    className="w-6 h-6"
                  />
                </div>
                  <p className="text-lg font-semibold" style={{ lineHeight: '44px', fontSize: '36px', color: '#32D583' }}>{totalConnections}</p>
              </div>

              <div className="bg-white p-4 rounded shadow-md" style={{ cursor: 'pointer', textDecoration: 'none', width:'290px'}} onClick={handleConnectionClick}>
                <h2 className="text-lg font-semibold mb-6" style={{ lineHeight: '24px', fontSize: '16px', color: '#010808' }}>Total Connections</h2>
                <p className="text-lg font-semibold" style={{ lineHeight: '44px', fontSize: '36px', color: '#010808' }}>{totalConnections}</p>
              </div>

              <div className="bg-white p-4 flex items-center justify-initial md:justify-center md:p-0 rounded shadow-md" style={{ lineHeight: '24px', fontSize: '16px', color: '#010808', width:'290px', height:'140px' }}>
                <button 
                  onClick={toggleModalConnection}
                  className="text-lg font-semibold"
                >
                  New Connection
                </button>
              </div>
            </div>
            <div className="w-full border-b-2 mb-2 mt-2 border-gray-100"></div>
          </div>
    );
  }
  
  const DevicesContent = () => (
    <div className='flex md:flex-row flex-col md:gap-48 gap-6 pt-3'>
            <div className='flex flex-row gap-2 md:items-center md:justify-center'>
              <img
                src={device}
                alt="User"
                className="w-6 h-6"
              />
              <span className='text-lg font-semibold' style={{ lineHeight: '24px', fontSize: '16px', color: '#010808' }}>Devices</span>
            </div>

            <div className='flex flex-col md:flex-row gap-4 items-center justify-center'>
              <div className="p-4 rounded shadow-md justify-center" style={{ backgroundColor: '#F05D38'  , cursor: 'pointer', textDecoration: 'none', width:'290px'}} onClick={handleDeviceClick}>
                <div className='flex flex-row justify-between gap-6' style={{ lineHeight: '24px', fontSize: '16px', color: '#010808' }}>
                  <h2 className="text-lg font-semibold mb-6" style={{ lineHeight: '24px', fontSize: '16px', color: '#FFFFFF' }}>Active Device</h2>
                    <img
                      src={alertdevice}
                      alt="User"
                      className="w-6 h-6"
                    />
                </div>
                  <p className="text-lg font-semibold mb-2" style={{ lineHeight: '44px', fontSize: '36px', color: '#FFFFFF' }}>{totalDevices}</p>
              </div>

              <div className="bg-white p-4 rounded shadow-md" style={{ cursor: 'pointer', textDecoration: 'none', width:'290px'}} onClick={handleDeviceClick}>
                <h2 className="text-lg font-semibold mb-6" style={{ lineHeight: '24px', fontSize: '16px', color: '#010808' }}>Total devices</h2>
                <p className="text-lg font-semibold" style={{ lineHeight: '44px', fontSize: '36px', color: '#010808' }}>{totalDevices}</p>
              </div>

              <div className="bg-white p-4 flex items-center justify-initial md:justify-center md:p-0 rounded shadow-md" style={{ lineHeight: '24px', fontSize: '16px', color: '#010808', width:'290px', height:'140px' }}>
                <button
                  onClick={userData && userData.role === "Company User" && userData && userData.role === "Company Admin" ? undefined : toggleModalDevice}
                  className={`text-lg font-semibold ${userData && userData.role === "Company User" && userData && userData.role === "Company Admin" ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                  New Device
              </button>
        </div>
            </div>
            <div className="w-full border-b-2 mb-6 border-gray-100"></div>
          </div>
  );
  
  const handleAddBooking = (newBookings) => {
    console.log(newBookings);
    setBookings(currentBookings => [...currentBookings, newBookings]);
  }
 
  const ActionsContent = () => (
    <div className='flex flex-col md:flex-row gap-10 md:gap-52 pt-6'>
            <div className='flex flex-row gap-2 md:items-center md:justify-center'>
              <img
                src={action}
                alt="User"
                className="w-6 h-6"
              />
              <span className='text-lg font-semibold' style={{ lineHeight: '24px', fontSize: '16px', color: '#010808' }}>Actions</span>
            </div>

            <div className='flex flex-col md:flex-row gap-4 items-center justify-center'>
              <div className="bg-white p-4 rounded shadow-md"  style={{ cursor: 'pointer', textDecoration: 'none', width:'290px'}}>
                <h2 className="text-lg font-semibold mb-2" style={{ lineHeight: '24px', fontSize: '16px', color: '#010808' }}>Devices Available</h2>
                <p className="text-lg font-semibold mb-2" style={{ lineHeight: '44px', fontSize: '36px', color: '#010808' }}>{totalActions}</p>
              </div>
            </div>
          </div>
  );

  useEffect(() => {
    fetchDeviceData();
    fetchConnectionData();
    fetchBookingData();
    fetchEventData();
    fetchActionData();
  }, []);

  return (
    <div {...handlers} className="md:mx-16 contain control pt-0 md:pt-8 md:px-8">
      <div className='text-black md:flex md:flex-row lg:flex lg:flex-row items-center gap-3 hidden'>
        <img
          src={icon}
          alt="User"
          className=""
        />
        <FontAwesomeIcon icon={faChevronRight} className="" />
        <span className='font-bold'>Dashboard</span>
      </div>

      <div className="bg-gray-100 pt-6 text-black flex flex-col items-start md:flex-row lg:items-center lg:justify-between">
        <div className="flex flex-col mb-6">
          <span className="text-md font-bold" style={{fontSize:'36px', color:'#010808', lineHeight:'44px', letterSpacing:'2%'}}>Dashboard</span>
          <span className="text-gray-600 text-base" style={{fontSize:'16px', lineHeight:'24px', letterSpacing:'0%'}}>Entro Dashboard - Your overview point!</span>
        </div>
      </div>

      <div className='md:hidden'>
        <select 
          onChange={(e) => setSelectedMenuItem(e.target.value)} 
          value={selectedMenuItem} 
          className="focus:outline-none dropdown"
        >
          <option value="All">All</option>
          <option value="Bookings">Bookings</option>
          <option value="Events">Events</option>
          <option value="Connections">Connections</option>
          <option value="Devices">Devices</option>
          <option value="Actions">Actions</option>
        </select>
        <div className="content px-12 bg-gray-50 py-4">
          {selectedMenuItem === 'All' && (
            <>
              <BookingsContent />
              <EventsContent />
              <ConnectionsContent />
              <DevicesContent />
              <ActionsContent />
            </>
          )}

          {selectedMenuItem === 'Bookings' && <BookingsContent />}
          {selectedMenuItem === 'Connections' && <ConnectionsContent />}
          {selectedMenuItem === 'Devices' && <DevicesContent />}
          {selectedMenuItem === 'Events' && <EventsContent />}
          {selectedMenuItem === 'Actions' && <ActionsContent />}
        </div>
      </div>

      <div className='hidden md:flex flex-col md:px-10 md:mr-12 bg-gray-50 py-4'>
          <div className='flex flex-col md:flex-row gap-10 md:gap-44'>
              <div className='flex flex-row gap-2 md:items-center md:justify-center'>
                <img
                  src={bookingd}
                  alt="User"
                  className="w-6 h-6"
                />
                <span className='text-lg font-semibold' style={{ lineHeight: '24px', fontSize: '16px', color: '#010808' }}>Bookings</span>
              </div>

              <div className='flex flex-col md:flex-row gap-4'>
                <div className="p-4 rounded shadow-md" style={{ backgroundColor: '#FEF0C7',cursor: 'pointer', textDecoration: 'none', width:'256px'}} onClick={handleCodeClick}>
                  <div className='flex flex-row justify-between gap-6 ' style={{ lineHeight: '24px', fontSize: '16px', color: '#010808', cursor: 'pointer', textDecoration: 'none' }}>
                    <h2 className="text-left text-lg font-semibold mb-6" style={{ lineHeight: '24px', fontSize: '16px', color: '#010808' }}>Active bookings</h2>
                        <img
                          src={alert}
                          alt="User"
                          className="w-6 h-6"
                        />
                  </div>
                    <p className="text-lg font-semibold mb-2" style={{ lineHeight: '44px', fontSize: '36px', color: '#010808' }}>{activeBookingsCount}</p>
                </div>

                <div className="bg-white p-4 rounded shadow-md" style={{ cursor: 'pointer', textDecoration: 'none', width:'256px'}} onClick={handleCodeClickNext}>
                  <h2 className="text-lg font-semibold mb-6" style={{ lineHeight: '24px', fontSize: '16px', color: '#010808' }}>Next 24 hours</h2>
                  <p className="text-lg font-semibold mb-2" style={{ lineHeight: '44px', fontSize: '36px', color: '#010808' }}>{bookingsNext24HoursCount}</p>
                </div>

                <div onClick={toggleModalBooking} className="bg-white p-4 flex items-center justify-initial md:justify-center md:p-0 rounded shadow-md" style={{ lineHeight: '24px', fontSize: '16px', color: '#010808', width:'256px', height:'140px' }}>
                  <button 
                    className="text-lg font-semibold mb-2"
                  >
                    New Booking
                  </button>
                </div>
              </div>
          </div>
          <div className="w-full border-b-2 mb-6 mt-6 border-gray-100"></div>
          <div className='flex flex-col md:flex-row gap-10 md:gap-48'>
            <div className='flex flex-row gap-2 md:items-center md:justify-center'>
              <img
                src={event}
                alt="User"
                className="w-6 h-6"
              />
              <h2 className='text-lg font-semibold' style={{ lineHeight: '24px', fontSize: '16px', color: '#010808' }}>Events</h2>
            </div>

            <div className='flex flex-col md:flex-row gap-4 md:pl-2'>
              <div className="bg-white p-4 rounded shadow-md" style={{ cursor: 'pointer', textDecoration: 'none', width:'256px'}} onClick={handleEventLast24Hours}>
                <div className='flex flex-row justify-between gap-8'>
                <h2 className="font-semibold mb-6" style={{ lineHeight: '24px', fontSize: '16px', color: '#010808' }}>Last 24 hours</h2>
                </div>
                  <p className="text-lg font-semibold mb-2" style={{ lineHeight: '44px', fontSize: '36px', color: '#32D583' }}>{eventsLast24Hours}</p>
              </div>

              <div className="bg-white p-4 rounded shadow-md" style={{ cursor: 'pointer', textDecoration: 'none', width:'256px'}} onClick={handleEventFailClick}>
                <h2 className="text-lg font-semibold mb-6" style={{ lineHeight: '24px', fontSize: '16px', color: '#010808' }}>Total Failed</h2>
                <p className="text-lg font-semibold mb-2 text-red-500" style={{ lineHeight: '44px', fontSize: '36px'}}>{totalFailed}</p>
              </div>

              <div className="bg-white flex items-center justify-center rounded shadow-md px-4">
              <canvas id="eventPieChartLarge" style={{ width: '226px', height: 'auto' }}></canvas>
              </div>
            </div>
          </div>

          <div className="w-full border-b-2 mb-6 mt-6 border-gray-100"></div>
          
          <div className='flex md:flex-row flex-col md:gap-48 gap-10'>
            <div className='flex flex-row gap-2 md:items-center md:justify-center'>
              <img
                src={device}
                alt="User"
                className="w-6 h-6"
              />
              <span className='text-lg font-semibold' style={{ lineHeight: '24px', fontSize: '16px', color: '#010808' }}>Devices</span>
            </div>

            <div className='flex flex-col md:flex-row gap-4'>
              <div className="p-4 rounded shadow-md" style={{ backgroundColor: '#F05D38'  , cursor: 'pointer', textDecoration: 'none', width:'256px'}} onClick={handleDeviceClick}>
                <div className='flex flex-row justify-between gap-6' style={{ lineHeight: '24px', fontSize: '16px', color: '#010808' }}>
                  <h2 className="text-lg font-semibold mb-6" style={{ lineHeight: '24px', fontSize: '16px', color: '#FFFFFF' }}>Active Device</h2>
                    <img
                      src={alertdevice}
                      alt="User"
                      className="w-6 h-6"
                    />
                </div>
                  <p className="text-lg font-semibold mb-2" style={{ lineHeight: '44px', fontSize: '36px', color: '#FFFFFF' }}>{totalDevices}</p>
              </div>

              <div className="bg-white p-4 rounded shadow-md" style={{ cursor: 'pointer', textDecoration: 'none', width:'256px'}}>
                <h2 className="text-lg font-semibold mb-6" style={{ lineHeight: '24px', fontSize: '16px', color: '#010808' }}>Total devices</h2>
                <p className="text-lg font-semibold" style={{ lineHeight: '44px', fontSize: '36px', color: '#010808' }}>{totalDevices}</p>
              </div>

              <div onClick={(e) => {
                  if (userData && userData.role === "Company Admin") {
                    e.preventDefault();
                    e.stopPropagation();
                  } else if (userData && userData.role === "Company User") {
                    e.preventDefault();
                    e.stopPropagation();
                  } else {
                    toggleModalDevice();
                  }
                }} 
                className="bg-white p-4 flex items-center justify-initial md:justify-center md:p-0 rounded shadow-md" 
                style={{ lineHeight: '24px', fontSize: '16px', color: '#010808', width:'256px', height:'140px' }}
              >
                <button
                  onClick={(e) => {
                    if (userData && userData.role === "Company Admin") {
                      e.preventDefault();
                      e.stopPropagation();
                    } else if (userData && userData.role === "Company User") {
                      e.preventDefault();
                      e.stopPropagation();
                    } else {
                      toggleModalDevice();
                    }
                  }}
                  className={`text-lg font-semibold ${userData && userData.role === "System Admin" ? '' : 'opacity-50 cursor-not-allowed'}`}
                >
                  New Device
                </button>
              </div>
            </div>
          </div>

          <div className="w-full mt-2 border-b-2 mb-6 border-gray-100"></div>

          <div className='flex flex-col md:flex-row gap-10 md:gap-40 md:pl-1'>
            {userData && userData.role === "System Admin" && (
              <>
                <div className='flex flex-row gap-2 md:items-center md:justify-center'>
                  <img
                    src={connect}
                    alt="User"
                    className="w-6 h-6"
                  />
                  <h2 className='text-lg font-semibold' style={{ lineHeight: '24px', fontSize: '16px', color: '#010808' }}>Connections</h2>
                </div>

                <div className='flex flex-col md:flex-row gap-4'>
                  <div className="bg-white p-4 rounded shadow-md" style={{ cursor: 'pointer', textDecoration: 'none', width:'250px'}} onClick={handleConnectionClick}>
                    <div className='flex flex-row justify-between gap-8'>
                      <h2 className="font-semibold mb-6" style={{ lineHeight: '24px', fontSize: '16px', color: '#010808' }}>Active Connection</h2>
                      <img
                        src={clock}
                        alt="User"
                        className="w-6 h-6"
                      />
                    </div>
                    <p className="text-lg font-semibold" style={{ lineHeight: '44px', fontSize: '36px', color: '#32D583' }}>{totalConnections}</p>
                  </div>

                  <div className="bg-white p-4 rounded shadow-md" style={{ cursor: 'pointer', textDecoration: 'none', width:'256px'}} onClick={handleConnectionClick}>
                    <h2 className="text-lg font-semibold mb-6" style={{ lineHeight: '24px', fontSize: '16px', color: '#010808' }}>Total Connections</h2>
                    <p className="text-lg font-semibold" style={{ lineHeight: '44px', fontSize: '36px', color: '#010808' }}>{totalConnections}</p>
                  </div>

                  <div onClick={toggleModalConnection} className="bg-white p-4 flex items-center justify-initial md:justify-center md:p-0 rounded shadow-md" style={{ lineHeight: '24px', fontSize: '16px', color: '#010808', width:'256px', height:'140px' }}>
                    <button 
                      className="text-lg font-semibold"
                    >
                      New Connection
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="w-full border-b-2 mb-6 mt-6 border-gray-100"></div>

          <div className='flex flex-col md:flex-row gap-10 md:gap-52'>
            <div className='flex flex-row gap-2 md:items-center md:justify-center'>
              <img
                src={action}
                alt="User"
                className="w-6 h-6"
              />
              <span className='text-lg font-semibold' style={{ lineHeight: '24px', fontSize: '16px', color: '#010808' }}>Actions</span>
            </div>

            <div className='flex flex-row gap-4' onClick={handleActionClick}>
              <div className="bg-white p-4 rounded shadow-md"  style={{ cursor: 'pointer', textDecoration: 'none', width:'256px'}}>
                <h2 className="text-lg font-semibold mb-2" style={{ lineHeight: '24px', fontSize: '16px', color: '#010808' }}>Devices Available</h2>
                <p className="text-lg font-semibold mb-2" style={{ lineHeight: '44px', fontSize: '36px', color: '#010808' }}>{totalActions}</p>
              </div>
            </div>
          </div>
      </div>

      {showModalDevice && (
        <EControlUnitForm
          closeModal={toggleModalDevice}
          editMode={false}
          deviceData={null}
        />
      )}

      {showModalBooking && (
        <NewBookingForm
          closeModal={toggleModalBooking}
          editMode={false}
          bookingData={null}
          data = {null}
          userData={userData}
          updateTableDataAdd={handleAddBooking}
        />
      )}

      {showModalConnection && (
        <ConnectionForm
          closeModal={toggleModalConnection}
          editMode={false}
          connectionData={null}
          onAddConnection={handleAddConnection}
        />
      )}
    </div>
  )
}

export default Dashboard