import React from 'react';
import { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { CSVLink } from 'react-csv';
import TableUser from './TableUsers';
import UsersForm from './UsersForm';
import icon from '../Assets/iconS.svg';
import download from '../Assets/download.svg';
import plus from '../Assets/plus.svg';
import search from '../Assets/search.svg';
import './styles.css';

const Users = ({setIsMenuOpen}) => {
  const headings = ["userId", "firstName","email", "username","company", "role", "actions", "Actions"];

  const [data, setData] = useState([]);
  const [filter, setFilter] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [result, setResult] = useState([]);
  const [rows, setRows] = useState([]);
  const [selectedUserData, setSelectedUserData] = useState(null);
  const [userDatas, setUserDatas] = useState(null);
  // const [companies, setCompanies] = useState([]);

  useEffect(() => {
    const userDataFromStorage = localStorage.getItem('userData');
    if (userDataFromStorage) {
      const parsedUserData = JSON.parse(userDataFromStorage);
      setUserDatas(parsedUserData);
    }
  }, []);

  // useEffect(() => {
  //   const fetchCompanies = async () => {
  //     try {
  //       const response = await axios.get('https://entro-api.onrender.com/api/getcompanydetails');
  //       setCompanies(response.data);
  //     } catch (error) {
  //       console.error('Error fetching companies:', error);
  //     }
  //   };
  //   fetchCompanies();
  // }, []);

  const onUpdateRole = (updatedUserData) => {
    console.log('Updated user data:', updatedUserData);
    axios.post('https://entro-api.onrender.com/api/updateuser', updatedUserData)
      .then(response => {
        setRows(prevData => {
          const newData = prevData.map(user => {
            if (user.userId === updatedUserData.userId) {
              return { ...user, ...updatedUserData };
            }
            return user;
          });
          console.log(newData); 
          return newData;
        });
      })
      .catch(error => {
        console.error('Error updating user details:', error);
      });
  };

  useEffect(() => {
    const fetchDataByRole = async () => {
      try {
        if (userDatas && userDatas.role === 'Company User') {
          const response = await axios.get('https://entro-api.onrender.com/api/getuser');
          const filteredData = response.data.filter(user => 
            user.role !== 'System Admin' && user.company === userDatas.company
          );
          setData(filteredData);
          setResult(filteredData);
          setRows(filteredData);
        }
        if (userDatas && userDatas.role === 'Company Admin') {
          const response = await axios.get('https://entro-api.onrender.com/api/getuser');
          const filteredData = response.data.filter(user => 
            user.role !== 'System Admin' && user.company === userDatas.company
          );
          setData(filteredData);
          setResult(filteredData);
          setRows(filteredData);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

  fetchDataByRole();
}, [userDatas]);

  useEffect(() => {
    const fetchDataByAdmin = async () => {
      try {
        if (userDatas && userDatas.role === 'System Admin') {
          const response = await axios.get('https://entro-api.onrender.com/api/getuser');
          console.log('Response status:', response.status);
          console.log('Data fetched:', response.data);
          setData(response.data);
          setResult(response.data);
          setRows(response.data)
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchDataByAdmin();
  }, [userDatas]);
  
  const handleDelete = async (userId) => {
    try {
      console.log('Dynamic unitId:', userId);
      const response = await axios.post('https://entro-api.onrender.com/api/deleteuser', { userId });

      console.log('Response status:', response.status);
      console.log('Message:', response.data.msg);

      if (response.status === 200) {
        const updatedData = data.filter((user) => user.userId !== userId);
        setData(updatedData);

        const filteredData = applyFilter(updatedData, filter, headings);
        setResult(filteredData);

        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
        setRows(currentItems);
      } else {
        console.error('Error deleting device:', response.data.error);
      }
    } catch (error) {
      console.error('Error deleting device:', error);
    }
  };
  
  const applyFilter = (data, filter, headings) => {
    if (filter.length >= 3) {
      const filtered = data.filter((row) => {
        const valuesToFilter = headings.map((heading) => {
          const rowValue = String(row[heading] || '').toLowerCase();
          return rowValue.includes(filter.toLowerCase());
        });
        return valuesToFilter.some((filteredValue) => filteredValue);
      });
      return filtered;
    } else {
      return data;
    }
  };

  const handleFilterChange = (value) => {
    setFilter(value);
  
    if (value.length >= 3) {
      const filteredData = data.filter((row) => {
        const valuesToFilter = headings.map((heading) => {
          const rowValue = String(row[heading] || '').toLowerCase();
          return rowValue.includes(value.toLowerCase());
        });
        return valuesToFilter.some((filteredValue) => filteredValue);
      });
      setResult(filteredData);
    } else {
      setResult(data);
    }
  
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = result.slice(indexOfFirstItem, indexOfLastItem);
    setRows(currentItems);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedUserData(null);
  };

  const handleEditUser = (userData) => {
    setSelectedUserData(userData);
    setShowModal(true);
  };

  const updateTableData = (updatedUserData) => {
    const updatedRows = rows.map(row => {
      if (row.userId === updatedUserData.userId) {
        return updatedUserData;
      }
      return row;
    });
  
    setRows(updatedRows);
    const updatedData = data.map(user => {
      if (user.userId === updatedUserData.userId) {
        return updatedUserData;
      }
      return user;
    });
  
    setData(updatedData);
  };  

  const updateTableDataAdd = (updatedUserData) => {
    const newRows = [updatedUserData, ...rows];
    setRows(newRows);
  }; 

  const handlers = useSwipeable({
    onSwipedLeft: () => setIsMenuOpen(false),
    onSwipedRight: () => setIsMenuOpen(true),
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
    trackMouse: false
  });

  return (
    <div className="container control md:pt-8">
      <div {...handlers} className="md:px-8">
        <div className='md:flex md:flex-row lg:flex lg:flex-row items-center gap-3 hidden' style={{color:"#010808"}}>
          <img
            src={icon}
            alt="User"
            className=""
          />
          <FontAwesomeIcon icon={faChevronRight} className="" />
          <span className='font-bold'>Users</span>
        </div>

        <div className="bg-gray-100 flex flex-col items-start  md:flex-row md:items-center md:justify-between">
          <div className="flex flex-col pb-8 lg:pt-0">
            <span className="font-bold" style={{fontSize:'36px', color:'#010808', lineHeight:'44px', letterSpacing:'2%'}}>Users</span>
            <p className="text-gray-600 text-base" style={{fontSize:'16px', lineHeight:'24px', letterSpacing:'0%'}}>View all users and edit as needed below.</p>
          </div>

          <div className="flex flex-col mt-0 md:mt-4">
            <div className="flex flex-col items-start md:flex-row md:items-center md:justify-end gap-2">
              <CSVLink
                data={data}
                headers={headings}
                filename={'users.csv'}
                className="custom-width flex items-center justify-center gap-2 border bg-white border-gray-300 md:px-3 py-2"
                style={{ color: "#010808"}}
              >
                <img
                  src={download}
                  alt="User"
                  className=""
                />
                <span className='font-bold' style={{ fontSize: '18px', lineHeight: '28px', color: '#010808', letterSpacing: '0%' }}>Export</span>
              </CSVLink>

              {userDatas && userDatas.role !== 'Company User' && (
                <button onClick={openModal} className="flex items-center justify-center gap-2 border text-white border-gray-300 w-full md:w-auto py-2 md:px-3" style={{ backgroundColor: '#010808' }}>
                  <img src={plus} alt="User" className="" />
                  <span className='font-bold' style={{ fontSize: '18px', lineHeight: '28px', backgroundColor: '#010808', letterSpacing: '0%' }}>New User</span>
                </button>
              )}
            </div>

            <div className="custom-widthl relative mt-6 md:mt-0">
              <span className="text-black hidden lg:block md:block pt-6">Search</span>
              <img
                  src={search}
                  alt="User"
                  className="absolute mt-5 pt-1 transform -translate-y-1/2 left-3 text-gray-500"
                />
              <input
                className="w-full px-10 text-gray-500 py-2 border rounded focus:outline-none"
                type="text"
                placeholder="Search by keyword"
                value={filter}
                onChange={(e) => handleFilterChange(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-8 md:mx-8 overflow-x-auto">
        <TableUser 
          headings={headings} 
          rows={rows} 
          onDelete={handleDelete} 
          onEditUser={handleEditUser} 
          updateTableData={updateTableData} 
          onUpdateRole={onUpdateRole}
          userData = {userDatas}
          // companies={companies}
        />
      </div>

      {showModal && (
        <div className="p-8">
          <div className="bg-white">
            <UsersForm
              closeModal={closeModal}
              editMode={selectedUserData !== null}
              userData={selectedUserData}
              userDatas= {userDatas}
              onConnectionUpdated={updateTableData}
              updateTableDataAdd={updateTableDataAdd}
              rows={rows}
              setRows={setRows}
            />
          </div>
        </div>
      )}

    </div>
  );
};

export default Users;