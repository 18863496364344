import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setUserData } from '../../actions';
import { faSpinner, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { loginApi } from '../../js/api';
import photos from '../../Assets/Group 1N.svg';
import './logout.css';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  // const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/login') {
      localStorage.clear();
      sessionStorage.clear();
    }
  }, [location.pathname]);
  
  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await loginApi(email, password);
      console.log(response);

      if (response && response.result && response.result.length > 0) {
        const userData = response.result[0];
        console.log(userData);
        localStorage.setItem('userId', userData.userId);
        localStorage.setItem('role', userData.role);
        localStorage.setItem('company', userData.company);
        localStorage.setItem('userData', JSON.stringify(userData));
        const sessionId = new Date().getTime();
        localStorage.setItem('sessionId', sessionId);
        localStorage.setItem('lastLoginChange', new Date().toISOString());
        dispatch(setUserData({ userData: userData }));
        history.push('/admin/dashboard', { userData });
      } else {
        setError('Login failed: Invalid credentials');
      }
    } catch (error) {
      setError('Login failed: ' + error.message);
    } finally {
      setLoading(false);
    }
  };
  
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  
  return (
    <div className="bg-gray-100 min-h-screen flex items-center justify-center">
      <div className='items-center'>
        <div 
          className="flex flex-row items-center justify-center gap-3 px-4 py-2 mb-4 text-white">
            <img
              src={photos}
              alt="User"
              className="rectangle-full object-cover"
            />
        </div>

        <h2 className="text-4xl mb-6 text-center font-bold" style={{ color:'#010808', letterSpacing:'2%'}}>Log in to Your Account</h2>
        <span className='text-black text-center mb-6 flex items-center justify-center'>
          Welcome back! Please enter your details.
        </span>

        <form onSubmit={handleLogin}>
          <div className="mb-6">
            <label className=" text-gray-700">Email</label>
            <input
              placeholder='Enter your Email'
              className="mt-1 w-full p-2 border text-gray-500 focus:outline-none"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="mb-2">
            <label className="text-gray-700 mb-2">Password</label>
            <div className="relative">
              <input
                placeholder='Enter your Password'
                className="w-full p-2 border focus:outline-none" 
                style={{color: '#667085'}}
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                className="absolute right-3 top-3 mt-0.5 cursor-pointer text-gray-500"
                onClick={togglePasswordVisibility}
              />
            </div>
          </div>

          <div className='flex flex-row justify-end gap-1 pb-6 pt-4'>
            {/* <input
              type="checkbox"
              id="rememberMe"
              className={`mr-2 ${rememberMe ? 'checked' : ''}`}
              checked={rememberMe}
              onChange={() => setRememberMe(!rememberMe)}
            />
              <label htmlFor="rememberMe" className="text-black text-sm">
                Remember for 30 days
              </label> */}
              <Link to="/forgot-password" className="text-sm pl-28">
                Forgot password
              </Link>
          </div>
          
          <div className="mb-6">
            <button
              className="w-full bg-black text-white disabled:opacity-50 py-2"
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <span>
                  <FontAwesomeIcon icon={faSpinner} spin /> Login...
                </span>
              ) : (
                'Sign in'
              )}
            </button>
          </div>
        </form>

        {error && <div className="text-red-500 mb-4 flex items-center justify-center">{error}</div>}
      </div>
    </div>
  );
};

export default Login;
