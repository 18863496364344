import React, { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import './styles.css';
import close from '../Assets/close.svg';
import EmailPopup from './EmailModal';
import RepeatAppointmentPopup from './Recurring';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const NewBookingForm = ({ closeModal, editMode, bookingData, updateTableDataAdd, onBookingUpdated }) => {  
  const [valid, setValid] = useState(false);
  const [formMode, setFormMode] = useState('single');
  const [registrationStatus, setRegistrationStatus] = useState(null);
  const [responseMessage, setResponseMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [updateStatus, setUpdateStatus] = useState(null);
  const [userData, setUserData] = useState(null);
  const [appointmentsCount, setAppointmentsCount] = useState(0);
  const [formErrors, setFormErrors] = useState({});
  const [companies, setCompanies] = useState([{ company: 'Select Company', CompanyID: null }]);
  const [formData, setFormData] = useState({
    bookingCustomerName: '',
    bookingCustomerPhone: '',
    bookingCode: '',
    bookingStartDate: '',
    bookingStartsAtTime: '',
    bookingEndDate: '',
    bookingEndsAtTime: '',
    bookingCompanyEmail:'',
    eConformationEmail: false,
    eConformationText: 'false',
    company: '',
    bookingdescription: '',
  });
  const [endDate, setEndDate] = useState(null);
  const [gradientHeight, setGradientHeight] = useState('');
  const [showEmailPopup, setShowEmailPopup] = useState(false);
  const [emailSubject, setEmailSubject] = useState('');
  const [emailBody, setEmailBody] = useState('');
  const [emailError, setEmailError] = useState('');
  const [showRepeatPopup, setShowRepeatPopup] = useState(false);
  const [selectedRecurringOption, setSelectedRecurringOption] = useState('Never');
  
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        closeModal();
      }
  };  
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [closeModal]);

  const stripHtml = (html) => {
    return html.replace(/<[^>]*>?/gm, '');
  }

  useEffect(() => {
    const updateGradientHeight = () => {
      const height = formMode === 'recurring' 
        ? (window.innerWidth < 640 ? '12%' : '13%')
        : (window.innerWidth < 640 ? '12%' : '14%');
      setGradientHeight(height);
    };
  
    window.addEventListener('resize', updateGradientHeight);
    updateGradientHeight();
    
    return () => {
      window.removeEventListener('resize', updateGradientHeight);
    };
  }, [formMode]);

  let companyID;
  if (userData && (userData.role === 'Company User'|| userData.role ==='Company Admin')) {
    const companyDetail = companies.find(c => c.company === userData.company);
    companyID = companyDetail ? companyDetail.company_ID : null;
  } else {
    const selectedCompany = companies.find(company => company.company === formData.company);
    companyID = selectedCompany ? selectedCompany.company_ID : null;
  }

  const handleChangeCheckbox = (e) => {
    const { id, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [id]: checked ? "true" : "false"
    }));
  
    if (id === 'eConformationEmail') {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (checked && emailPattern.test(formData.bookingCompanyEmail)) {
        setShowEmailPopup(true);
        setEmailError('');
      } else {
        setShowEmailPopup(false);
        if (checked) {
          setEmailError('Please enter a valid email address before enabling send email.');
        } else {
          setEmailError('');
        }
      }
    }
  };  

  const validateForm = () => {
    const errors = {};
    if (!formData.bookingCustomerName.trim()) errors.bookingCustomerName = 'Name is required.';
    if (formData.bookingCode.length !== 6) errors.bookingCode = 'Code must be 6 characters long.';
    if (!formData.bookingStartDate.trim()) errors.bookingStartDate = 'Start date is required.';
    if (!formData.bookingStartsAtTime.trim()) errors.bookingStartsAtTime = 'Start time is required';
    if (!formData.bookingEndsAtTime.trim()) errors.bookingEndsAtTime = 'End time is required';
  
    // Require bookingEndDate only if formMode is single
    if (formMode === 'single' && !formData.bookingEndDate.trim()) {
      errors.bookingEndDate = 'End date is required.';
    }

    if (formData.bookingStartDate === formData.bookingEndDate &&
      formData.bookingStartsAtTime >= formData.bookingEndsAtTime) {
      errors.bookingEndsAtTime = 'End time must be greater than start time.';
    }
  
    if (userData && userData.role !== 'Company User' && !formData.company) {
      errors.company = 'Company is required';
    }
  
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };  
  
  useEffect(() => {
    const userDataFromStorage = localStorage.getItem('userData');
    if (userDataFromStorage) {
      const parsedUserData = JSON.parse(userDataFromStorage);
      setUserData(parsedUserData);
    }
  }, []); 

  useEffect(() => {
    async function fetchCompanies() {
      try {
        const response = await axios.get('https://entro-api.onrender.com/api/getcompanydetails');
        if (response.status === 200) {
          setCompanies(prev => [{ company: 'Select Company', CompanyID: null }, ...response.data]);
        }
      } catch (error) {
        console.error('Failed to fetch companies:', error);
      }
    }
    fetchCompanies();
  }, []);  

  const calculateRepeatCount = (startDate, endDate, intervalOption) => {
    if (!startDate || !endDate) return 0;
  
    const start = new Date(startDate);
    const end = new Date(endDate);
  
    const diffTime = end - start;
    const diffDays = diffTime / (1000 * 60 * 60 * 24);
  
    switch (intervalOption) {
      case 'Every week':
        return Math.ceil(diffDays / 7);
      case 'Every 2 weeks':
        return Math.ceil(diffDays / 14);
      case 'Every 3 weeks':
        return Math.ceil(diffDays / 21);
      case 'Every 4 weeks':
        return Math.ceil(diffDays / 28);
      case 'Every 5 weeks':
        return Math.ceil(diffDays / 35);
      case 'Every 6 weeks':
        return Math.ceil(diffDays / 42);
      case 'Every 7 weeks':
        return Math.ceil(diffDays / 49);
      case 'Every 8 weeks':
        return Math.ceil(diffDays / 56);
      case 'Every 3 months (12 weeks)':
        return Math.ceil(diffDays / 84);
      case 'Every 4 months (16 weeks)':
        return Math.ceil(diffDays / 112);
      case 'Every 5 months (20 weeks)':
        return Math.ceil(diffDays / 140);
      case 'Every 6 months (24 weeks)':
        return Math.ceil(diffDays / 168);
      case 'Every year':
        return Math.ceil(diffDays / 365);
      default:
        return 0;
    }
  };  
  
  useEffect(() => {
    if (formData.bookingStartDate && endDate && selectedRecurringOption) {
      const count = calculateRepeatCount(formData.bookingStartDate, endDate, selectedRecurringOption);
      setAppointmentsCount(count);
    }
  }, [formData.bookingStartDate, endDate, selectedRecurringOption]);
    
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'company') {
      const selectedCompany = companies.find(company => company.company === value);
      setFormData(prevData => ({
        ...prevData,
        [name]: value,
        company_ID: selectedCompany ? selectedCompany.company_ID : '',
      }));
    } 
    // else if (name === 'Connection') {
    //   // setFormData(prevData => ({
    //   //   ...prevData,
    //   //   [name]: value,
    //   // }));
    // } 
    else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  function formatDate(dateString) {
    if (!dateString) return '';
    
    const parts = dateString.split('/');
    if (parts.length === 3) {
      return `${parts[0]}-${parts[1]}-${parts[2]}`;
    }
    return dateString;
  }

  useEffect(() => {
    if (editMode && bookingData) {
      setFormData({
        bookingCustomerName: bookingData.bookingCustomerName || '',
        bookingCustomerPhone: bookingData.bookingCustomerPhone || '',
        bookingCode: bookingData.bookingCode || '',
        bookingStartDate: bookingData.bookingStartDate ? formatDate(bookingData.bookingStartDate) : '',
        bookingStartsAtTime: bookingData.bookingStartsAtTime || '',
        bookingEndDate: bookingData.bookingEndDate ? formatDate(bookingData.bookingEndDate) : '',
        bookingEndsAtTime: bookingData.bookingEndsAtTime || '',
        bookingCompanyEmail: bookingData.bookingCompanyEmail || '',
        endDate:bookingData.bookingEndDate || '',
        Connection: bookingData.Connection || 'None',
        company: bookingData.company || 'None',
        company_ID: bookingData.company_ID || null,
        isRecurring:bookingData.isRecurring || null,
        bookingdescription: bookingData.bookingdescription || '',
      });
      if (bookingData.isRecurring === 'true') {
        setSelectedRecurringOption(bookingData.selectedRecurringOption || 'Never');
        setEndDate(new Date(bookingData.bookingEndDate));
      }
    }
  }, [editMode, bookingData]);

  useEffect(() => {
    setValid(
      formData.bookingCustomerName &&
      formData.bookingCustomerPhone &&
      formData.bookingStartsAtTime &&
      formData.bookingEndsAtTime &&
      formData.bookingCode?.length === 6 &&
      formData.bookingStartDate &&
      formData.bookingCompanyEmail &&
      formData.bookingEndDate
    );
  }, [formData]);
  
  useEffect(() => {
    if (formMode === 'single') {
      setValid(
        formData.bookingCustomerName &&
        formData.bookingCustomerPhone &&
        formData.bookingCode?.length === 6 &&
        formData.bookingStartDate &&
        formData.bookingEndDate &&
        formData.bookingCompanyEmail
      );
    } else if (formMode === 'recurring') {
      setValid(
        formData.bookingCustomerName &&
        formData.bookingCustomerPhone &&
        formData.bookingCode?.length === 6 &&
        formData.bookingStartDate &&
        formData.bookingEndDate &&
        formData.bookingCompanyEmail &&
        formData.company
      );
    }
  }, [formMode, formData]);

  const toggleFormMode = (mode) => {
    setFormMode(mode);
  };

  const getRRuleFromOption = (option, endDate) => {
    const dateISO = endDate ? `${endDate.getFullYear()}/${String(endDate.getMonth() + 1).padStart(2, '0')}/${String(endDate.getDate()).padStart(2, '0')}` : '';
    
    switch (option) {
      case 'Every week':
        return `FREQ=WEEKLY;UNTIL=${dateISO}`;
      case 'Every 2 weeks':
        return `FREQ=WEEKLY;INTERVAL=2;UNTIL=${dateISO}`;
      case 'Every 4 weeks':
        return `FREQ=WEEKLY;INTERVAL=4;UNTIL=${dateISO}`;
      case 'Every 6 weeks':
        return `FREQ=WEEKLY;INTERVAL=6;UNTIL=${dateISO}`;
      case 'Every 7 weeks':
        return `FREQ=WEEKLY;INTERVAL=7;UNTIL=${dateISO}`;
      case 'Every 8 weeks':
        return `FREQ=WEEKLY;INTERVAL=8;UNTIL=${dateISO}`;
      case 'Every 9 weeks':
        return `FREQ=WEEKLY;INTERVAL=9;UNTIL=${dateISO}`;
      case 'Every 10 weeks':
        return `FREQ=WEEKLY;INTERVAL=10;UNTIL=${dateISO}`;
      case 'Every 3 months (12 weeks)':
        return `FREQ=WEEKLY;INTERVAL=12;UNTIL=${dateISO}`;
      case 'Every 4 months (16 weeks)':
        return `FREQ=WEEKLY;INTERVAL=16;UNTIL=${dateISO}`;
      case 'Every 5 months (20 weeks)':
        return `FREQ=WEEKLY;INTERVAL=20;UNTIL=${dateISO}`;
      case 'Every 6 months (24 weeks)':
        return `FREQ=WEEKLY;INTERVAL=24;UNTIL=${dateISO}`;
      case 'Every year':
        return `FREQ=YEARLY;UNTIL=${dateISO}`;
      default:
        return '';
    }
  };

  const convertToEpoch = (date, time, isEndTime = false, startDate) => {
    let dateTime = new Date(`${date}T${time}`);
  
    // Adjust for end time at 00:00 on the same day as start date
    if (isEndTime && time === "00:00" && date === startDate) {
      dateTime = new Date(`${date}T23:59:59`);
    }
  
    // Convert to UTC epoch (second resolution)
    const utcEpoch = Date.UTC(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate(), dateTime.getHours(), dateTime.getMinutes(), dateTime.getSeconds());
    return Math.floor(utcEpoch / 1000);
  };
  

  const handleSave = async () => {
    if (!validateForm()) {
        console.error('Validation failed.');
        return;
    }
    const isRecurring = formMode === 'recurring' ? 'true' : 'false';
    if (editMode) {
        handleUpdateBooking(isRecurring);
    } else {
        if (isRecurring === 'true') {
            const intervals = calculateRepeatCount(formData.bookingStartDate, endDate, selectedRecurringOption);
            let currentStartDate = new Date(formData.bookingStartDate);
            const finalEndDate = new Date(endDate);
            const rrule = getRRuleFromOption(selectedRecurringOption, endDate);

            const daysToAdd = {
                'Every week': 7,
                'Every 2 weeks': 14,
                'Every 3 weeks': 21,
                'Every 4 weeks': 28,
                'Every 5 weeks': 35,
                'Every 6 weeks': 42,
                'Every 7 weeks': 49,
                'Every 8 weeks': 56,
                'Every 9 weeks': 63,
                'Every 10 weeks': 70,
                'Every 3 months (12 weeks)': 84,
                'Every 4 months (16 weeks)': 112,
                'Every 5 months (20 weeks)': 140,
                'Every 6 months (24 weeks)': 168,
                'Every year': 365
            };

            for (let i = 0; i < intervals; i++) {
                const currentEndDate = new Date(currentStartDate);
                currentEndDate.setDate(currentStartDate.getDate() + (daysToAdd[selectedRecurringOption] - 1));

                if (currentEndDate > finalEndDate) {
                    currentEndDate.setTime(finalEndDate.getTime());
                }

                const startDateISO = `${currentStartDate.getFullYear()}-${String(currentStartDate.getMonth() + 1).padStart(2, '0')}-${String(currentStartDate.getDate()).padStart(2, '0')}`;
                const endDateISO = `${currentEndDate.getFullYear()}-${String(currentEndDate.getMonth() + 1).padStart(2, '0')}-${String(currentEndDate.getDate()).padStart(2, '0')}`;
                const startEpoch = convertToEpoch(formData.bookingStartDate, formData.bookingStartsAtTime);
                // Ensure the isEndTime flag is true and pass the startDate for comparison
                const endEpoch = convertToEpoch(formData.bookingEndDate, formData.bookingEndsAtTime, true, formData.bookingStartDate);
              
                const bookingDetails = {
                  ...formData,
                  userId: userData?.userId,
                  company: userData?.role === 'Company User' ? userData.company : formData.company,
                  company_ID: companyID,
                  bookingStartDate: startDateISO,
                  bookingEndDate: endDateISO,
                  bookingStartsAtTime: startEpoch,
                  bookingEndsAtTime: endEpoch,
                  isRecurring,
                  rrule,
                  status: "notdone",
                  Integration: "web",
                  Appointments: 1,
                };

                try {
                    const response = await axios.post('https://entro-api.onrender.com/api/addbooking', bookingDetails);
                    if (response.status === 200) {
                        updateTableDataAdd(response.data.result);
                        setRegistrationStatus('success');
                    } else {
                        throw new Error('Failed to process booking');
                      }
                    } catch (error) {
                        console.error('Error submitting the booking:', error);
                        setRegistrationStatus('failure');
                    }
                currentStartDate.setDate(currentStartDate.getDate() + daysToAdd[selectedRecurringOption]);
                if (currentStartDate > finalEndDate) break;
            }
        } else {
          const bookingDetails = {
            ...formData,
            userId: userData?.userId,
            company: userData?.role === 'Company User' ? userData.company : formData.company,
            company_ID: companyID,
            status: "notdone",
            Integration: "web",
            bookingStartDate: formData.bookingStartDate,
            bookingEndDate: formData.bookingEndDate,
            bookingStartsAtTime: Math.floor(new Date(`${formData.bookingStartDate}T${formData.bookingStartsAtTime}:00Z`).getTime() / 1000),
            bookingEndsAtTime: Math.floor(new Date(`${formData.bookingEndDate}T${formData.bookingEndsAtTime}:00Z`).getTime() / 1000),
            isRecurring,
            Appointments: 1
        };

        try {
            const response = await axios.post('https://entro-api.onrender.com/api/addbooking', bookingDetails);
            if (response.status === 200) {
              updateTableDataAdd(response.data.result);
              setRegistrationStatus('success');
          } else {
              throw new Error('Failed to process booking');
            }
        } catch (error) {
          console.error('Error submitting the booking:', error);
          setRegistrationStatus('failure');
        }
      }
    }
  };

  const handleUpdateBooking = async (isRecurring) => {
    if (isRecurring === 'true') {
        const intervals = calculateRepeatCount(formData.bookingStartDate, endDate, selectedRecurringOption);
        let currentStartDate = new Date(formData.bookingStartDate);
        const finalEndDate = new Date(endDate);
        const rrule = getRRuleFromOption(selectedRecurringOption, endDate);

        const daysToAdd = {
            'Every week': 7,
            'Every 2 weeks': 14,
            'Every 3 weeks': 21,
            'Every 4 weeks': 28,
            'Every 5 weeks': 35,
            'Every 6 weeks': 42,
            'Every 7 weeks': 49,
            'Every 8 weeks': 56,
            'Every 9 weeks': 63,
            'Every 10 weeks': 70,
            'Every 3 months (12 weeks)': 84,
            'Every 4 months (16 weeks)': 112,
            'Every 5 months (20 weeks)': 140,
            'Every 6 months (24 weeks)': 168,
            'Every year': 365
        };

        for (let i = 0; i < intervals; i++) {
            const currentEndDate = new Date(currentStartDate);
            currentEndDate.setDate(currentStartDate.getDate() + (daysToAdd[selectedRecurringOption] - 1));
            if (currentEndDate > finalEndDate) {
                currentEndDate.setTime(finalEndDate.getTime());
            }

            const startDateISO = `${currentStartDate.getFullYear()}-${String(currentStartDate.getMonth() + 1).padStart(2, '0')}-${String(currentStartDate.getDate()).padStart(2, '0')}`;
            const endDateISO = `${currentEndDate.getFullYear()}-${String(currentEndDate.getMonth() + 1).padStart(2, '0')}-${String(currentEndDate.getDate()).padStart(2, '0')}`;

            const bookingDetails = {
              ...formData,
              bookingId: bookingData.bookingId,
              userId: userData?.userId,
              company: userData?.role === 'Company User' ? userData.company : formData.company,
              company_ID: companyID,
              bookingStartDate: startDateISO,
              bookingEndDate: endDateISO,
              status: "notdone",
              eConformationText: formData.eConformationText,
              Integration: "web",
              isRecurring,
              rrule,
              Appointments: 1
            };

            try {
                const response = await axios.post('https://entro-api.onrender.com/api/updatebooking', bookingDetails);
                if (response.status === 200) {
                    setUpdateStatus("success");
                    onBookingUpdated(response.data);
                } else {
                    throw new Error('Failed to update booking');
                }
            } catch (error) {
                console.error('Error updating the booking:', error);
                setUpdateStatus("failure");
            }

            currentStartDate.setDate(currentStartDate.getDate() + daysToAdd[selectedRecurringOption]);
            if (currentStartDate > finalEndDate) break;
        }
    } else {
        const bookingDetails = {
            ...formData,
            bookingId: bookingData.bookingId,
            userId: userData?.userId,
            company_ID: companyID,
            bookingStartDate: formData.bookingStartDate,
            Integration: "web",
            bookingEndDate: formData.bookingEndDate,
            isRecurring,
            status: "notdone",
            eConformationText: formData.eConformationText,
        };

        try {
            const response = await axios.post('https://entro-api.onrender.com/api/updatebooking', bookingDetails);
            if (response.status === 200) {
                setUpdateStatus("success");
                onBookingUpdated(response.data);
            } else {
                throw new Error('Failed to update booking');
            }
        } catch (error) {
            console.error('Error updating the booking:', error);
            setUpdateStatus("failure");
        }
    }
  };

  const handleSendEmail = async () => {
    const plainTextBody = stripHtml(emailBody);
    if (!emailSubject.trim() || !emailBody.trim()) {
      alert('Please provide both subject and message body.');
      return;
    }
  
    try {
      const response = await axios.post('https://entro-api.onrender.com/api/sendmail', {
        bookingCode: formData.bookingCode,
        Subject: emailSubject,
        Body: plainTextBody,
        company_ID: companyID,
        receiverEmail: formData.bookingCompanyEmail,
      });
      
      if (response.status === 200) {
        setResponseMessage('Email sent successfully!');
          setTimeout(() => {
            setResponseMessage('');
          }, 5000);
      } else {
        setErrorMessage(`Failed to send Email`);
          setTimeout(() => {
            setErrorMessage('');
          }, 5000);
        throw new Error('Failed to send email.');
      }
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email.');
    }
  }; 

  return (
      <div className="fixed inset-0 flex items-start p-2 pt-6 pb-2 justify-center bg-gray-500 bg-opacity-75 overflow-y-auto">
        <div 
          className="max-w-sm md:max-w-xl p-6 pt-5"
          style={{
            background: `linear-gradient(to bottom, #F3F4F6 ${gradientHeight}, #ffffff ${gradientHeight}, #ffffff 100%)`,
            borderBottom: '1px solid black'
          }}
        >
        <div className="flex flex-row gap-2 items-start justify-between pb-10">
            <div className='flex flex-col'>
             <span className="font-semibold" style={{fontSize:'36px', color:'#010808', lineHeight:'44px', letterSpacing:'2%'}}>{editMode ? 'Update Booking' : 'New Booking'}</span>
             <span className="text-gray-600" >{editMode ? 'Update Booking detail below' : 'Add your new Booking below'} </span>
            </div>
           <img
              onClick={closeModal}
              src={close}
              alt="User"
              className="cursor-pointer mt-4 pl-10"
           />
        </div>

        {registrationStatus === 'success' && (
          <div className="bg-green-200 text-green-800 p-2 mb-4">
            Registration successful!
          </div>
        )}

        {registrationStatus === 'failure' && (
          <div className="bg-red-200 text-red-800 p-2 mb-2">
            Registration failed. Please try again
          </div>
        )}

        {updateStatus === 'success' && (
          <div className="bg-green-200 text-green-800 p-2 mb-2">
            Update successful!
          </div>
        )}

        {updateStatus === 'failure' && (
          <div className="bg-red-200 text-red-800 p-2 mb-2">
            Update failed. Please try again.
          </div>
        )}

        <div className="flex flex-col md:flex-row gap-3 mb-6">
          <button
            className={`mr-2 w-full md:w-1/2 py-2 ${formMode === 'single' ? 'text-white bg-gray-800' : 'text-gray-600 bg-gray-50'}`}
            onClick={() => toggleFormMode('single')}
          >
            Single
          </button>
          <button
            className={`w-full md:w-1/2 py-2 text-white ${formMode === 'recurring' ? 'bg-gray-800' : 'bg-gray-300'}`}
            onClick={() => toggleFormMode('recurring')}
          >
            Recurring
          </button>
        </div>

          {formMode === 'single' && (
            <form>
              <div className="flex flex-wrap -mx-3 mb-2">
                <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-2 md:mb-6">
                  <label className="text-sm font-medium text-gray-900">
                    Customer name <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="bookingCustomerName"
                    placeholder='None'
                    value={formData.bookingCustomerName}
                    onChange={handleChange}
                    className={`${formErrors.bookingCustomerName ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                  />
                  {formErrors.bookingCustomerName && (
                    <p className="text-red-500 text-xs italic">{formErrors.bookingCustomerName}</p>
                  )}
                </div>
                
                <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-2 md:mb-0">
                  <label className="text-sm font-medium text-gray-900">
                    Phone 
                  </label>
                  <input
                    type="text"
                    name="bookingCustomerPhone"
                    value={formData.bookingCustomerPhone}
                    onChange={handleChange}
                    placeholder="None"
                    className={`mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                  />
                </div>

                <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-2 md:mb-0">
                  <label className="text-sm font-medium text-gray-900">
                    Code <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="bookingCode"
                    value={formData.bookingCode}
                    onChange={handleChange}
                    placeholder="None"
                    className={` ${formErrors.bookingCode ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                  />
                  {formErrors.bookingCode && (
                    <p className="text-red-500 text-xs italic">{formErrors.bookingCode}</p>
                  )}
                </div>
              </div>

              <div className="flex flex-wrap -mx-3">  
                <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-2 md:mb-0">
                  <label className="text-sm font-medium text-gray-900">Start Date <span className="text-red-500">*</span></label>
                  <input
                    type="date"
                    name="bookingStartDate"
                    value={formData.bookingStartDate}
                    onChange={handleChange}
                    placeholder="Select a start date"
                    className={` ${formErrors.bookingStartDate ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                  />
                  {formErrors.bookingStartDate && (
                    <p className="text-red-500 text-xs italic">{formErrors.bookingStartDate}</p>
                  )}
                </div>

                <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-2 md:mb-0">
                  <label className="text-sm font-medium text-gray-900" style={{color:'black'}}>Start Time <span className="text-red-500">*</span></label>
                    <input
                      type="time"
                      name='bookingStartsAtTime'
                      placeholder='None'
                      value={formData.bookingStartsAtTime}
                      onChange={handleChange}
                      className={` ${formErrors.bookingStartsAtTime ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                    required
                    />

                  {formErrors.bookingStartsAtTime && (
                    <p className="text-red-500 text-xs italic">{formErrors.bookingStartsAtTime}</p>
                  )}
                </div>

                <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-2 md:mb-0">
                  <label htmlFor="end-time" className='text-sm font-medium text-gray-900'>
                    End Date {formMode === 'single' && <span className="text-red-500">*</span>}
                  </label>
                  <input
                    type="date"
                    name="bookingEndDate"
                    value={formData.bookingEndDate}
                    onChange={handleChange}
                    className={` ${formErrors.bookingEndDate ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                  />
                  {formErrors.bookingEndDate && (
                    <p className="text-red-500 text-xs italic">{formErrors.bookingEndDate}</p>
                  )}
                </div>
              </div>

              <div className="flex flex-wrap -mx-3 mb-2 mt-6">
                <div className="w-full md:w-1/2  px-3 mb-2">
                <label htmlFor="end-time" className='text-sm font-medium text-gray-900'>
                  End Time {formMode === 'single' && <span className="text-red-500">*</span>}
                </label>
                <input
                  type="time"
                  name="bookingEndsAtTime"
                  value={formData.bookingEndsAtTime}
                  onChange={handleChange}
                  className={`${formErrors.bookingEndsAtTime ? 'border-red-500' : 'border-gray-300'} mt-1 py-2 px-2 w-full border text-gray-500 focus:outline-none`}
                />
                {formErrors.bookingEndsAtTime && (
                  <p className="text-red-500 text-xs italic">{formErrors.bookingEndsAtTime}</p>
                )}
                  
                </div>
                  
                <div className="w-full md:w-1/2 px-3 mb-2 md:mb-6">
                  <label className="text-sm font-medium text-gray-900">
                    Booking Email
                  </label>
                  <input
                    type="text"
                    name="bookingCompanyEmail"
                    placeholder="None"
                    value={formData.bookingCompanyEmail}
                    onChange={handleChange}
                    className={`mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                    />
                </div>

                <div className="w-full px-3 mb-2">
                  <label className="text-sm font-medium text-gray-900">
                    Description
                  </label>
                  <textarea
                    name="bookingdescription"
                    placeholder="Enter a description for the booking"
                    value={formData.bookingdescription}
                    onChange={handleChange}
                    className="mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none"
                    rows="3"
                  />
                </div>
    
                {userData && userData.role !== 'Company User' && (
                  <div className="w-full px-3 mb-2 md:mb-0">
                      <label className="text-sm font-medium text-gray-900">
                          Company <span className="text-red-500">*</span>
                      </label>
                      <select
                          name="company"
                          value={formData.company}
                          onChange={handleChange}
                          className={`mt-1 py-2 px-2 w-full border ${formErrors.company ? 'border-red-500' : 'border-gray-300'} text-gray-500 focus:outline-none`}
                      >
                          {companies.map((company) => (
                            <option key={company.CompanyID} value={company.company}>
                              {company.company}
                            </option>
                          ))}
                      </select>
                      {formErrors.company && (
                          <p className="text-red-500 text-xs italic">{formErrors.company}</p>
                      )}
                  </div>
                )}

                {/* <div className='p-4 flex flex-col'>
                  <label className=" text-gray-900 font-semibold pb-4">Notify user</label>
                  <div className='flex flex-row gap-2 items-center mb-2'>
                  <input
                    type="checkbox"
                    id="eConformationText"
                    checked={formData.eConformationText === 'true'}
                    onChange={handleChangeCheckbox}
                  />
                    <label htmlFor="rememberMe" className=" text-md" style={{fontSize:'14px', color:'#010808', lineHeight:'20px', letterSpacing:'0%'}}>
                      Text message
                    </label>

                    <input
                      type="checkbox"
                      id="eConformationEmail"
                      checked={formData.eConformationEmail === 'true'}
                      onChange={handleChangeCheckbox}
                    />
                    <label htmlFor="rememberMe" className=" text-md" style={{fontSize:'14px', color:'#010808', lineHeight:'20px', letterSpacing:'0%'}}>
                      Email
                    </label>

                    {emailError && (
                      <p className="text-red-500 text-xs italic">{emailError}</p>
                    )}

                    {showEmailPopup && (
                      <EmailPopup 
                        onClose={() => setShowEmailPopup(false)}
                        onSend={handleSendEmail}
                        setEmailSubject={setEmailSubject}
                        setEmailBody={setEmailBody}
                        emailSubject={emailSubject}
                        emailBody={emailBody}
                        setResponseMessage={setResponseMessage}
                        responseMessage={responseMessage}
                        errorMessage={errorMessage}
                        setErrorMessage={setErrorMessage}
                      />
                    )}
                  </div>
                </div> */}
              </div>
            </form>
          )}

          {formMode === 'recurring' && (
            <form>
              <div className="flex flex-wrap -mx-3 mb-2">
                <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-2 md:mb-6">
                  <label className="text-sm font-medium text-gray-900">
                    Name <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="bookingCustomerName"
                    placeholder='None'
                    value={formData.bookingCustomerName}
                    onChange={handleChange}
                    className={`${formErrors.bookingCustomerName ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                  />
                  {formErrors.bookingCustomerName && (
                    <p className="text-red-500 text-xs italic">{formErrors.bookingCustomerName}</p>
                  )}
                </div>
                
                <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-2 md:mb-0">
                  <label className="text-sm font-medium text-gray-900">
                    Phone 
                  </label>
                  <input
                    type="text"
                    name="bookingCustomerPhone"
                    value={formData.bookingCustomerPhone}
                    onChange={handleChange}
                    placeholder="None"
                    className={`mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                  />
                  {/* {formErrors.bookingCustomerPhone && (
                    <p className="text-red-500 text-xs italic">{formErrors.bookingCustomerPhone}</p>
                  )} */}
                </div>

                <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-2 md:mb-0">
                  <label className="text-sm font-medium text-gray-900">
                    Code <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="bookingCode"
                    value={formData.bookingCode}
                    onChange={handleChange}
                    placeholder="None"
                    className={` ${formErrors.bookingCode ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                  />
                  {formErrors.bookingCode && (
                    <p className="text-red-500 text-xs italic">{formErrors.bookingCode}</p>
                  )}
                </div>
              </div>

              <div className="flex flex-wrap -mx-3">  
                <div className="w-full md:w-1/2 px-3 mb-2 md:mb-0">
                  <label className="text-sm font-medium text-gray-900">Start Date <span className="text-red-500">*</span></label>
                  <input
                    type="date"
                    name="bookingStartDate"
                    value={formData.bookingStartDate}
                    onChange={handleChange}
                    placeholder="Select a start date"
                    className={` ${formErrors.bookingStartDate ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                  />
                  {formErrors.bookingStartDate && (
                    <p className="text-red-500 text-xs italic">{formErrors.bookingStartDate}</p>
                  )}
                </div>

                <div className="w-full md:w-1/2 px-3 mb-2 md:mb-0">
                  <label className="text-sm font-medium text-gray-900" style={{color:'black'}}>Start Time <span className="text-red-500">*</span></label>
                    <input
                      type="time"
                      name='bookingStartsAtTime'
                      placeholder='None'
                      value={formData.bookingStartsAtTime}
                      onChange={handleChange}
                      className={` ${formErrors.bookingStartsAtTime ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                      required
                    />

                  {formErrors.bookingStartsAtTime && (
                    <p className="text-red-500 text-xs italic">{formErrors.bookingStartsAtTime}</p>
                  )}
                </div>

                {/* <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-2 md:mb-0">
                  <label htmlFor="end-time" className='text-sm font-medium text-gray-900'>End Date <span className="text-red-500">*</span></label>
                  <input
                    type="date"
                    name="bookingEndDate"
                    value={formData.bookingEndDate}
                    onChange={handleChange}
                    className={` ${formErrors.bookingEndDate ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                  />
                    {formErrors.bookingEndDate && (
                      <p className="text-red-500 text-xs italic">{formErrors.bookingEndDate}</p>
                    )}
                </div> */}
              </div>

              <div className="flex flex-wrap -mx-3 mb-2 mt-6">
                <div className="w-full md:w-1/2  px-3 mb-2">
                  <label className="text-sm font-medium text-gray-900" style={{color:'black'}}>End Time <span className="text-red-500">*</span></label>
                  <input
                    type="time"
                    name='bookingEndsAtTime'
                    placeholder='None'
                    value={formData.bookingEndsAtTime}
                    onChange={handleChange}
                    className={` ${formErrors.bookingEndsAtTime ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                  />

                  {formErrors.bookingEndsAtTime && (
                    <p className="text-red-500 text-xs italic">{formErrors.bookingEndsAtTime}</p>
                  )}
                </div>
                  
                <div className="w-full md:w-1/2 px-3 mb-2 md:mb-6">
                  <label className="text-sm font-medium text-gray-900">
                    Booking Email
                  </label>
                  <input
                    type="text"
                    name="bookingCompanyEmail"
                    placeholder="None"
                    value={formData.bookingCompanyEmail}
                    onChange={handleChange}
                    className={`mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                  />
                </div>

                <div className="w-full px-3 mb-2">
                  <label className="text-sm font-medium text-gray-900">
                    Description
                  </label>
                  <textarea
                    name="bookingdescription"
                    placeholder="Enter a description for the booking"
                    value={formData.bookingdescription}
                    onChange={handleChange}
                    className="mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none"
                    rows="3"
                  />
                </div>
    
                {userData && userData.role !== 'Company User' && (
                  <div className="w-full px-3 mb-2 md:mb-0">
                      <label className="text-sm font-medium text-gray-900">
                          Company <span className="text-red-500">*</span>
                      </label>
                      <select
                          name="company"
                          value={formData.company}
                          onChange={handleChange}
                          className={`mt-1 py-2 px-2 w-full border ${formErrors.company ? 'border-red-500' : 'border-gray-300'} text-gray-500 focus:outline-none`}
                      >
                          {companies.map((company) => (
                            <option key={company.CompanyID} value={company.company}>
                              {company.company}
                            </option>
                          ))}
                      </select>
                      {formErrors.company && (
                          <p className="text-red-500 text-xs italic">{formErrors.company}</p>
                      )}
                  </div>
                )}

                <div className='p-4 flex flex-col'>
                  {/* <label className=" text-gray-900 font-semibold pb-4">Notify user</label> */}
                  <div className='flex flex-row gap-2 items-center mb-2'>
                  {/* <input
                    type="checkbox"
                    id="eConformationText"
                    checked={formData.eConformationText === 'true'}
                    onChange={handleChangeCheckbox}
                  />
                    <label htmlFor="rememberMe" className=" text-md" style={{fontSize:'14px', color:'#010808', lineHeight:'20px', letterSpacing:'0%'}}>
                      Text message
                    </label>

                    <input
                      type="checkbox"
                      id="eConformationEmail"
                      checked={formData.eConformationEmail === 'true'}
                      onChange={handleChangeCheckbox}
                    />
                    <label htmlFor="rememberMe" className=" text-md" style={{fontSize:'14px', color:'#010808', lineHeight:'20px', letterSpacing:'0%'}}>
                      Email
                    </label>

                    {emailError && (
                      <p className="text-red-500 text-xs italic">{emailError}</p>
                    )} */}

                    {showEmailPopup && (
                      <EmailPopup 
                        onClose={() => setShowEmailPopup(false)}
                        onSend={handleSendEmail}
                        setEmailSubject={setEmailSubject}
                        setEmailBody={setEmailBody}
                        emailSubject={emailSubject}
                        emailBody={emailBody}
                        setResponseMessage={setResponseMessage}
                        responseMessage={responseMessage}
                        errorMessage={errorMessage}
                        setErrorMessage={setErrorMessage}
                      />
                    )}

                    {showRepeatPopup && (
                      <RepeatAppointmentPopup
                        closePopup={() => setShowRepeatPopup(false)}
                        selectedRecurringOption={selectedRecurringOption}
                        setSelectedRecurringOption={setSelectedRecurringOption}
                        endDate={endDate}
                        setEndDate={setEndDate} 
                        bookingStartDate={formData.bookingStartDate}
                        calculateRepeatCount={calculateRepeatCount}
                      />
                    )}

                  </div>
                </div>

              </div>

              {editMode && (
                <div className="flex flex-col mb-4">
                  <label htmlFor="isRecurring" className="text-sm font-medium text-gray-900">
                    Recurring Booking
                  </label>
                  <select
                    id="isRecurring"
                    name="isRecurring"
                    value={formData.isRecurring}
                    onChange={(e) => setFormData({ ...formData, isRecurring: e.target.value })}
                    className="mt-1 block w-full pl-3 py-2 text-gray-700 border border-gray-300 focus:outline-none sm:text-sm"
                  >
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                  </select>
                </div>
              )}

            {formMode === 'recurring' && !editMode && (
              <div className="flex justify-between items-center text-black bg-white border py-2 px-4 rounded-lg">
                <span className="font-semibold">Repeat</span>
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowRepeatPopup(true);
                  }}
                  className="text-gray-500"
                >
                  {selectedRecurringOption} <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
                </button>
              </div>
            )}

            {editMode && formData.isRecurring === 'true' && (
              <div className="flex justify-between items-center text-black bg-white border py-2 px-4 rounded-lg">
              <span className="font-semibold">Repeat</span>
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setShowRepeatPopup(true);
                }}
                className="text-gray-500"
              >
                {selectedRecurringOption} <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
              </button>
            </div>
            
            )}

            {/* <div className="mb-2 mt-4">
              <label htmlFor="repeats" className="block text-md font-medium text-gray-700">Repeats:</label>
              <select
                id="repeats"
                name="repeats"
                value={formData.repeats}
                onChange={handleChange}
                className="mt-1 block w-full pl-3 py-2 text-gray-700 border border-gray-300 focus:outline-none sm:text-sm"
              >
                <option value="Daily">Daily</option>
                <option value="Weekly">Weekly</option>
                <option value="Monthly">Monthly</option>
                <option value="Yearly">Yearly</option>
              </select>
                {renderRepeatOptions()}
            </div> */}

            <div className="flex flex-wrap -mx-3 mb-2">
            </div>
          </form>
          )}

          <div className="flex md:flex-row md:justify-end flex-col gap-2 pt-6">
            <button
              onClick={closeModal}
              className="px-3 py-2 text-gray-600 bg-white border"
            >
              Discard
            </button>
            
            <button
              onClick={handleSave}
              type="submit"
              className='py-2 px-3 bg-black text-white hover:bg-teal-600 focus:outline-none focus:ring focus:border-teal-300'
              style={{ backgroundColor: '#010808' }}
            >
              {editMode ? 'Save changes' : 'Add Booking'}
            </button>
         </div>
      </div>
      </div>
   );
};

export default NewBookingForm;
