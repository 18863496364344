import React, { useState, useEffect } from 'react';
import axios from 'axios';
import close from '../Assets/close.svg';
import icelandIcon from '../Assets/is.svg';
import franceIcon from '../Assets/et.svg';
import ukIcon from '../Assets/uk.svg';
import './styles.css';
import { usePhoneNumberFormat } from './Provided';

const SettingsModal = ({ isOpen, closeModal, companyData, updateCompanyData, userData }) => {
  const [switchStates, setSwitchStates] = useState(false);
  const [smsLeadTime, setSmsLeadTime] = useState(0);
  const [phoneNumberLocale, setPhoneNumberLocale] = useState('');
  const [displayIntl, setDisplayIntl] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [defaultMessage, setDefaultMessage] = useState('');
  const [smsSenderName, setSmsSenderName] = useState('');
  const [smsSenderEmail, setSmsSenderEmail] = useState('');
  const [smsSenderEmailN, setSmsSenderEmailN] = useState('');
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('');
  const [automaticMessage, setAutomaticMessage] = useState('');
  const { isInternational, toggleInternationalFormat } = usePhoneNumberFormat();
  const [gradientHeight, setGradientHeight] = useState('');
  const [switchStatesCalender, setSwitchStatesCalender] = useState(false);
  const [switchStatesSms, setSwitchStatesSms] = useState(false);
  const [automaticMessageCreate, setAutomaticMessageCreate] = useState('');
  const [automaticMessageAlert, setAutomaticMessageAlert] = useState('');
  const [automaticMessageCalender, setAutomaticMessageCalender] = useState('');
  const [automaticMessageCursorCalender, setAutomaticMessageCursorCalender] = useState(0);
  const [automaticMessageCursor, setAutomaticMessageCursor] = useState(0);
  const [defaultMessageCursor, setDefaultMessageCursor] = useState(0);
  const [automaticMessageCursorCreate, setAutomaticMessageCursorCreate] = useState(0);
  const [automaticMessageCursorAlert, setAutomaticMessageCursorAlert] = useState(0);
  const [noonaText, setNoonaText] = useState('');
  const [noonaTextCursor, setNoonaTextCursor] = useState(0);

  useEffect(() => {
    const updateGradientHeight = () => {
      const height = window.innerWidth < 640 ? '3.9%' : '4.9%';
      setGradientHeight(height);
    };
    window.addEventListener('resize', updateGradientHeight);
    updateGradientHeight();
    return () => {
      window.removeEventListener('resize', updateGradientHeight);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        closeModal();
      }
  };  
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [closeModal]);

  const localeOptions = [
    { value: 'IS', label: 'IS - Iceland', icon: icelandIcon },
    { value: 'US', label: 'US - United States', icon: ukIcon },
    { value: 'ET', label: 'ET - Ethiopia', icon: franceIcon },
    {value: 'IN', label: 'IN- India'}
  ];

  const replaceSpecialChars = (str) => {
    const charMap = {
      'ö': 'o', 'ä': 'a', 'ü': 'u', 'ß': 'ss',
      'þ': 'th',
      'à': 'a', 'á': 'a', 'â': 'a', 'ã': 'a', 'å': 'a', 'æ': 'ae',
      'ç': 'c', 'è': 'e', 'é': 'e', 'ê': 'e', 'ë': 'e',
      'ì': 'i', 'í': 'i', 'î': 'i', 'ï': 'i',
      'ð': 'd', 'ñ': 'n',
      'ò': 'o', 'ó': 'o', 'ô': 'o', 'õ': 'o', 'ø': 'o', 'œ': 'oe',
      'ù': 'u', 'ú': 'u', 'û': 'u',
      'ý': 'y', 'ÿ': 'y',
      'ā': 'a', 'ă': 'a', 'ą': 'a', 'ć': 'c', 'č': 'c', 'ĉ': 'c', 'ċ': 'c',
      'ď': 'd', 'đ': 'd', 'ē': 'e', 'ĕ': 'e', 'ė': 'e', 'ę': 'e', 'ě': 'e',
      'ğ': 'g', 'ġ': 'g', 'ģ': 'g', 'ħ': 'h', 'ĩ': 'i', 'ī': 'i', 'ĭ': 'i', 'į': 'i', 'ı': 'i',
      'ĳ': 'ij', 'ķ': 'k', 'ĸ': 'k', 'ĺ': 'l', 'ļ': 'l', 'ľ': 'l', 'ŀ': 'l', 'ł': 'l',
      'ń': 'n', 'ņ': 'n', 'ň': 'n', 'ŋ': 'n', 'ō': 'o', 'ŏ': 'o', 'ő': 'o',
      'ŕ': 'r', 'ŗ': 'r', 'ř': 'r', 'ś': 's', 'š': 's', 'ŝ': 's', 'ş': 's', 'ș': 's', 'ť': 't',
      'ţ': 't', 'ț': 't', 'ŧ': 't', 'ũ': 'u', 'ū': 'u', 'ŭ': 'u', 'ů': 'u', 'ű': 'u', 'ų': 'u',
      'ŵ': 'w', 'ŷ': 'y', 'ź': 'z', 'ż': 'z', 'ž': 'z',
      'a': 'a', 'b': 'b', 'c': 'c', 'd': 'd', 'e': 'e', 'f': 'f', 'g': 'g', 'h': 'h',
      'i': 'i', 'j': 'j', 'k': 'k', 'l': 'l', 'm': 'm', 'n': 'n', 'o': 'o', 'p': 'p',
      'q': 'q', 'r': 'r', 's': 's', 't': 't', 'u': 'u', 'v': 'v', 'w': 'w', 'x': 'x',
      'y': 'y', 'z': 'z'
    };
    
    return str.split('').map(char => charMap[char] || char).join('');
  };

  useEffect(() => {
    if (companyData && userData) {
      setDefaultMessage(companyData.Body || `your access code to ${companyData.company} Open 8-23:00`);
      setSwitchStates(companyData.SendSmsBeforeBooking === "true");
      setSwitchStatesCalender(companyData.SendCalenderInvite === "true");
      setSwitchStatesSms(companyData.SendSms === "true");
      setSmsLeadTime(companyData.SmsLeadTime || 0);
      setPhoneNumberLocale(companyData.phoneNumberLocale);
      setDisplayIntl(companyData.displayIntl === "true");
      setSmsSenderName(companyData.smsSenderName || companyData.company);
      setSmsSenderEmailN(companyData.smsSenderEmailN || companyData.company);
      setSmsSenderEmail(companyData.smsSenderEmail || companyData.ContactEmail);
      setAutomaticMessage(companyData.AutomaticNotifiationSMS);
      setAutomaticMessageCalender(companyData.CalenderBody);
      setAutomaticMessageCreate(companyData.CreationBody);
      setAutomaticMessageAlert(companyData.AlertBody);
      setNoonaText(companyData.BookingDetails);
      formatPhoneNumber();
    }
  }, [companyData, userData]);

  useEffect(() => {
    formatPhoneNumber();
  }, [phoneNumberLocale, displayIntl, companyData.ContactNumber]);

  const insertShortcode = (shortcode, target) => {
    const safeSubstring = (text, start, end) => text?.substring(start, end) || '';
  
    switch (target) {
      case 'automaticMessage':
        setAutomaticMessage((prev) =>
          safeSubstring(prev, 0, automaticMessageCursor) +
          shortcode +
          safeSubstring(prev, automaticMessageCursor)
        );
        break;
      case 'defaultMessage':
        setDefaultMessage((prev) =>
          safeSubstring(prev, 0, defaultMessageCursor) +
          shortcode +
          safeSubstring(prev, defaultMessageCursor)
        );
        break;
      case 'noonaText':
        setNoonaText((prev) =>
          safeSubstring(prev, 0, noonaTextCursor) +
          shortcode +
          safeSubstring(prev, noonaTextCursor)
        );
        break;
      case 'automaticMessageCreate':
        setAutomaticMessageCreate((prev) =>
          safeSubstring(prev, 0, automaticMessageCursorCreate) +
          shortcode +
          safeSubstring(prev, automaticMessageCursorCreate)
        );
        break;
      case 'automaticMessageCalender':
        setAutomaticMessageCalender((prev) =>
          safeSubstring(prev, 0, automaticMessageCursorCalender) +
          shortcode +
          safeSubstring(prev, automaticMessageCursorCalender)
        );
        break;
      case 'automaticMessageAlert':
        setAutomaticMessageAlert((prev) =>
          safeSubstring(prev, 0, automaticMessageCursorAlert) +
          shortcode +
          safeSubstring(prev, automaticMessageCursorAlert)
        );
        break;
      default:
        console.warn(`Invalid target: ${target}`);
    }
  };
  
  const handleCursor = (event, setter) => {
    setter(event.target.selectionStart || 0);
  };
  
  const formatPhoneNumber = () => {
    let exampleNumber = companyData.ContactNumber || '663 8555';
    let formattedBaseNumber = `${exampleNumber.slice(0, 3)} ${exampleNumber.slice(3)}`;
  
    if (displayIntl) {
      // eslint-disable-next-line default-case
      switch (phoneNumberLocale) {
        case 
        'IS':
          formattedBaseNumber = `+354 ${formattedBaseNumber}`;
          break;
        case 'US':
          formattedBaseNumber = `+1 ${formattedBaseNumber}`;
          break;
        case 'ET':
          formattedBaseNumber = `+251 ${formattedBaseNumber}`;
          break;
        case 'IN':
          formattedBaseNumber =  `+91 ${formattedBaseNumber}`
      }
    }
    setFormattedPhoneNumber(formattedBaseNumber);
  };
  
  const handleSave = (event) => {
    event.preventDefault();
    const normalizedSenderName = replaceSpecialChars(smsSenderName)
    const payload = {
      SendSmsBeforeBooking: switchStates.toString(),
      SmsLeadTime: parseInt(smsLeadTime, 10),
      company_ID: companyData.company_ID,
      phoneNumberLocale: phoneNumberLocale,
      displayIntl: isInternational.toString(),
      smsSenderName: normalizedSenderName,
      smsSenderEmail: smsSenderEmail,
      Body: defaultMessage,
      BookingDetails:noonaText,
      AutomaticNotifiationSMS: automaticMessage,
      CreationBody: automaticMessageCreate,
      CalenderBody: automaticMessageCalender,
      AlertBody: automaticMessageAlert,
      SendCalenderInvite: switchStatesCalender.toString(),
      SendSms: switchStatesSms.toString(),
    };

    axios.post('https://entro-api.onrender.com/api/updatecompanydetails', payload)
      .then(response => {
        console.log('SMS settings updated:', response.data);
        setSuccessMessage("SMS settings successfully updated.");
        if(response.data) {
          updateCompanyData({ ...companyData, ...payload });
        } else {
          console.error('Updated company data not received');
        }
        setTimeout(() => {
          setSuccessMessage('');
        }, 2000);
      })
      .catch(error => {
        console.error('Failed to update SMS settings:', error);
        setSuccessMessage("Failed to update settings. Please try again.");
        setTimeout(() => {
          setSuccessMessage('');
        }, 2000);
      });
  };

  if (!isOpen) {
    return null;
  }

  const handleToggleSlider = () => {
    setSwitchStates(prevState => !prevState);
  };

  const handleToggleSliderSms = () => {
    setSwitchStatesSms(prevState => !prevState);
  }

  const handleToggleSliderCalender = () => {
    setSwitchStatesCalender(prevState => !prevState);
  };

  const handleIntlSwitch = () => {
    toggleInternationalFormat();
    const newDisplayIntl = !isInternational;
    setDisplayIntl(newDisplayIntl);

    const payload = {
      company_ID: companyData.company_ID,
      displayIntl: newDisplayIntl.toString(),
    };

    axios.post('https://entro-api.onrender.com/api/updatecompanydetails', payload)
      .then(response => {
        console.log('International format updated:', response.data);
        updateCompanyData({ ...companyData, displayIntl: newDisplayIntl.toString() });
        setTimeout(() => {
          setSuccessMessage('');
        }, 2000);
      })
      .catch(error => {
        console.error('Failed to update international format:', error);
        setTimeout(() => {
          setSuccessMessage('');
        }, 2000);
      });
  };

  const handleLocaleChange = (event) => {
    setPhoneNumberLocale(event.target.value);
  };

  return (
    <div className={`fixed inset-0 flex items-start p-4 ${userData && userData.role === 'Company Admin' ? 'pt-4 pb-0' : 'pt-6'}  pb-6 justify-center bg-gray-500 bg-opacity-75 overflow-y-auto`}>
      <div className={`w-full ${userData && userData.role === 'Company Admin' ? 'md:max-w-xl ml-4 mr-4' : 'max-w-md md:max-w-xl'} p-6`}
        style={{
          background: `linear-gradient(to bottom, #F3F4F6 ${gradientHeight}, #ffffff ${gradientHeight}, #ffffff 100%)`,
          borderBottom: '1px solid black'
        }}
      >
        <div className="flex flex-row gap-2 items-start justify-between pb-14">
          <div className='flex flex-col'>
            <div className='flex flex-row gap-6 md:gap-16'>
              <h2 className="font-semibold pt-1" style={{fontSize:'36px', color:'#010808', lineHeight:'44px', letterSpacing:'2%'}}>{companyData.company}</h2>
              <h2 className="font-semibold text-gray-600 mt-1" style={{fontSize:'28px', lineHeight:'44px', letterSpacing:'2%'}}>Settings</h2>
            </div>
          </div>
          <img 
            onClick={closeModal} 
            src={close} alt="Close" 
            className="cursor-pointer mt-4 pl-8 md:mr-0" 
          />
        </div>

        {successMessage && (
          <div className="bg-green-200 text-green-800 p-2 mb-4">
            {successMessage}
          </div>
        )}

        <form onSubmit={handleSave}>
          <div className="mb-1">
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
              <div className="col-span-1 cursor-pointer">
                <label htmlFor="displayIntl" className="text-gray-900">
                  Display in International Format
                </label>
                <div className="custom-switch-bar1" onClick={handleIntlSwitch} style={{
                  backgroundColor: displayIntl ? '#349999' : '#c4c4c4',
                }}>
                  <span className='custom-switch-circle cursor-pointer' style={{
                    transform: displayIntl ? 'translateX(1.25rem)' : 'translateX(0)',
                    transition: 'transform 0.3s ease',
                  }}></span>
                </div>
              </div>

              <div className="mb-4">
            <label htmlFor="locale" className="text-gray-900">Phone Number Locale</label>
            <select
              id="locale"
              value={phoneNumberLocale}
              onChange={handleLocaleChange}
              className="mt-1 w-full border px-2 py-1.5 resize-none text-gray-600"
            >
              {localeOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
            </div>
           
            <div className="col-span-1">
                <label htmlFor="phoneNumber" className="text-gray-900">
                  Phone Number
                </label>
                <input
                  id="phoneNumber"
                  name="phoneNumber"
                  placeholder="None"
                  value={formattedPhoneNumber}
                  onChange={(e) => setSmsLeadTime(e.target.value)}
                  className="mt-1 px-2 py-1.5 w-full border resize-none text-gray-600 focus:outline-none"
                  required
                  disabled={true}
                ></input>
            </div>

            <div className="col-span-1 md:col-span-2 py-2">
              <label htmlFor="defaultMessage" className="text-gray-900">
                SMS Sender Name
              </label>
              <input
                id="defaultMessage"
                value={smsSenderName}
                onChange={(e) => setSmsSenderName(e.target.value)}
                className="mt-1 px-2 py-1.5 w-full border resize-none text-gray-600 focus:outline-none"
                required
              ></input>
            </div>

            <div className="col-span-1 md:col-span-2 py-2">
              <label htmlFor="defaultMessage" className="text-gray-900">
                Email Sender Name
              </label>
              <input
                id="email sender name"
                value={smsSenderEmailN}
                onChange={(e) => setSmsSenderEmailN(e.target.value)}
                className="mt-1 px-2 py-1.5 w-full border resize-none text-gray-600 focus:outline-none"
                required
              ></input>
            </div>

            {/* <div className="col-span-1 md:col-span-2 py-2">
              <label htmlFor="defaultMessage" className="text-gray-900">
                Email reply-to address
              </label>
              <input
                id="email to email"
                value={smsSenderEmail}
                onChange={(e) => setSmsSenderEmail(e.target.value)}
                className="mt-1 px-2 py-1.5 w-full border resize-none text-gray-600 focus:outline-none"
                required
              ></input>
            </div> */}

            <hr className="my-5" />
            <div className="text-center text-gray-900 font-semibold mb-3">At booking Creation</div>
            <hr className="my-5" />

            <div className='grid grid-cols-1 md:grid-cols-2 gap-4 '>
              <div className="col-span-1 cursor-pointer">
                <label htmlFor="smsActivity" className="text-gray-900">
                  Send Calendar Invite
                </label>
                <div className="custom-switch-bar1 cursor-pointer" onClick={handleToggleSliderCalender} style={{
                  backgroundColor: switchStatesCalender ? '#349999' : '#c4c4c4',
                  borderRadius: '0.75rem',
                  transition: 'all 0.3s ease',
                }}>
                  <span className='custom-switch-circle' style={{
                      transform: switchStatesCalender ? 'translateX(1.25rem)' : 'translateX(0)',
                      transition: 'transform 0.3s ease',
                  }}></span>
                </div>
              </div>

              <div className="col-span-1 cursor-pointer">
                <label htmlFor="smsActivity" className="text-gray-900">
                  Send SMS
                </label>
                <div className="custom-switch-bar1 cursor-pointer" onClick={handleToggleSliderSms} style={{
                  backgroundColor: switchStatesSms ? '#349999' : '#c4c4c4',
                  borderRadius: '0.75rem',
                  transition: 'all 0.3s ease',
                }}>
                  <span className='custom-switch-circle' style={{
                      transform: switchStatesSms ? 'translateX(1.25rem)' : 'translateX(0)',
                      transition: 'transform 0.3s ease',
                  }}></span>
                </div>
              </div>
            </div>

            <div className="col-span-1 md:col-span-2 py-4">
              <label htmlFor="automaticMessage" className="text-gray-900">
                Creation SMS
              </label>
              <textarea
                value={automaticMessageCreate}
                onChange={(e) => setAutomaticMessageCreate(e.target.value)}
                onClick={(e) => handleCursor(e, setAutomaticMessageCursorCreate)}
                onSelect={(e) => handleCursor(e, setAutomaticMessageCursorCreate)}
                className="mt-1 px-2 py-1.5 w-full border text-gray-600 focus:outline-none"
                required
              ></textarea>

              <div className="mt-2 flex flex-wrap gap-2">
                <button type="button" onClick={() => insertShortcode('{{customer name}}', 'automaticMessageCreate')} className="shortcode-btn hover:bg-blue-700 text-white font-bold py-2 px-2 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'customer name'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{code}}', 'automaticMessageCreate')} className="shortcode-btn bg-blue-700 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'code'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{booking start date}}', 'automaticMessageCreate')} className="shortcode-btn hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'booking start date'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{booking start time}}', 'automaticMessageCreate')} className="shortcode-btn hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'booking start time'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{booking end time}}', 'automaticMessageCreate')} className="shortcode-btn hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'booking end time'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{booking end date}}', 'automaticMessageCreate')} className="shortcode-btn hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'booking end date'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{company name}}', 'automaticMessageCreate')} className="shortcode-btn hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'company name'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{company id}}', 'automaticMessageCreate')} className="shortcode-btn hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'company id'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{booking id}}', 'automaticMessageCreate')} className="shortcode-btn hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'booking id'}
                </button>
              </div>

            </div>

            <div className="col-span-1 md:col-span-2 mt-4">
              <label htmlFor="automaticMessage" className="text-gray-900">
                Calendar invite text
              </label>
              <textarea
                id="automaticMessageCalender"
                value={automaticMessageCalender}
                onChange={(e) => setAutomaticMessageCalender(e.target.value)}
                onClick={(e) => handleCursor(e, setAutomaticMessageCursorCalender)}
                onSelect={(e) => handleCursor(e, setAutomaticMessageCursorCalender)}
                className="mt-1 px-2 py-1.5 w-full border text-gray-600 focus:outline-none"
                required
              ></textarea>

              <div className="mt-2 flex flex-wrap gap-2">
                <button type="button" onClick={() => insertShortcode('{{customer name}}', 'automaticMessageCalender')} className="shortcode-btn hover:bg-blue-700 text-white font-bold py-2 px-2 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'customer name'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{code}}', 'automaticMessageCalender')} className="shortcode-btn bg-blue-700 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'code'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{booking start date}}', 'automaticMessageCalender')} className="shortcode-btn hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'booking start date'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{booking start time}}', 'automaticMessageCalender')} className="shortcode-btn hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'booking start time'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{booking end time}}', 'automaticMessageCalender')} className="shortcode-btn hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'booking end time'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{booking end date}}', 'automaticMessageCalender')} className="shortcode-btn hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'booking end date'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{company name}}', 'automaticMessageCalender')} className="shortcode-btn hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'company name'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{company id}}', 'automaticMessageCalender')} className="shortcode-btn hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'company id'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{booking id}}', 'automaticMessageCalender')} className="shortcode-btn hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'booking id'}
                </button>
              </div>
            </div>

            <div className="col-span-1 md:col-span-2 mt-4">
              <label htmlFor="noonaText" className="text-gray-900">
                Booking Details Text
              </label>
              <textarea
                id="noonaText"
                value={noonaText}
                onChange={(e) => setNoonaText(e.target.value)}
                onClick={(e) => handleCursor(e, setNoonaTextCursor)}
                onSelect={(e) => handleCursor(e, setNoonaTextCursor)}
                className="mt-1 px-2 py-1.5 w-full border text-gray-600 focus:outline-none"
                required
              ></textarea>

              <div className="mt-2 flex flex-wrap gap-2">
                <button type="button" onClick={() => insertShortcode('{{customer name}}', 'noonaText')} className="shortcode-btn hover:bg-blue-700 text-white font-bold py-2 px-2 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'customer name'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{code}}', 'noonaText')} className="shortcode-btn hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'code'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{booking start date}}', 'noonaText')} className="shortcode-btn hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'booking start date'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{booking start time}}', 'noonaText')} className="shortcode-btn hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'booking start time'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{booking end time}}', 'noonaText')} className="shortcode-btn hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'booking end time'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{booking end date}}', 'noonaText')} className="shortcode-btn hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'booking end date'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{company name}}', 'noonaText')} className="shortcode-btn hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'company name'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{company id}}', 'noonaText')} className="shortcode-btn hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'company id'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{booking id}}', 'noonaText')} className="shortcode-btn hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'booking id'}
                </button>
              </div>

            </div>


            <hr className="my-4 font-semibold" />
            <div className="text-center text-gray-900 font-semibold mb-3">Before booked time</div>
            <hr className="my-4" />

            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
              <div className="col-span-1 cursor-pointer">
                <label htmlFor="smsActivity" className="text-gray-900">
                  Send SMS before booking
                </label>
                <div className="custom-switch-bar1 cursor-pointer" onClick={handleToggleSlider} style={{
                  backgroundColor: switchStates ? '#349999' : '#c4c4c4',
                  borderRadius: '0.75rem',
                  transition: 'all 0.3s ease',
                }}>
                  <span className='custom-switch-circle' style={{
                      transform: switchStates ? 'translateX(1.25rem)' : 'translateX(0)',
                      transition: 'transform 0.3s ease',
                  }}></span>
                </div>
              </div>

              <div className="col-span-1">
                <label htmlFor="smsLeadTime" className="text-gray-900">
                  SMS Lead Time (minutes)
                </label>
                <input
                  id="smsLeadTime"
                  name="smsLeadTime"
                  placeholder="None"
                  value={smsLeadTime}
                  onChange={(e) => setSmsLeadTime(e.target.value)}
                  className="mt-1 px-2 py-1.5 w-full border resize-none text-gray-600 focus:outline-none cursor-pointer"
                  required
                ></input>
              </div>
            </div>

            <div className="col-span-1 md:col-span-2 py-2">
              <label htmlFor="automaticMessage" className="text-gray-900">
                Alert SMS
              </label>
              <textarea
                id="automaticMessageAlert"
                value={automaticMessageAlert}
                onChange={(e) => setAutomaticMessageAlert(e.target.value)}
                onClick={(e) => handleCursor(e, setAutomaticMessageCursorAlert)}
                onSelect={(e) => handleCursor(e, setAutomaticMessageCursorAlert)}
                className="mt-1 px-2 py-1.5 w-full border text-gray-600 focus:outline-none"
                required
              ></textarea>

              <div className="mt-2 flex flex-wrap gap-2">
                <button type="button" onClick={() => insertShortcode('{{customer name}}', 'automaticMessageAlert')} className="shortcode-btn hover:bg-blue-700 text-white font-bold py-2 px-2 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'customer name'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{code}}', 'automaticMessageAlert')} className="shortcode-btn bg-blue-700 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'code'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{booking start date}}', 'automaticMessageAlert')} className="shortcode-btn hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'booking start date'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{booking start time}}', 'automaticMessageAlert')} className="shortcode-btn hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'booking start time'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{booking end time}}', 'automaticMessageAlert')} className="shortcode-btn hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'booking end time'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{booking end date}}', 'automaticMessageAlert')} className="shortcode-btn hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'booking end date'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{company name}}', 'automaticMessageAlert')} className="shortcode-btn hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'company name'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{company id}}', 'automaticMessageAlert')} className="shortcode-btn hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'company id'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{booking id}}', 'automaticMessageAlert')} className="shortcode-btn hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'booking id'}
                </button>
              </div>
            </div>

            <hr className="my-4" />
            <div className="text-center text-gray-900 font-semibold mb-3">Web Alert time</div>
            <hr className="my-4" />

            <div className="col-span-1 md:col-span-2">
              <label htmlFor="defaultMessage" className="text-gray-900">
                  Default Text Message
              </label>
              <textarea
                id="defaultMessage"
                value={defaultMessage}
                onChange={(e) => setDefaultMessage(e.target.value)}
                onClick={(e) => handleCursor(e, setDefaultMessageCursor)}
                onSelect={(e) => handleCursor(e, setDefaultMessageCursor)}
                className="mt-1 px-2 py-1.5 w-full border text-gray-600 focus:outline-none"
                required
              ></textarea>
              <div className="mt-2 flex flex-wrap gap-2">
                <button type="button" onClick={() => insertShortcode('{{customer name}}', 'defaultMessage')} className="shortcode-btn hover:bg-blue-700 text-white font-bold py-2 px-2 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'customer name'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{code}}', 'defaultMessage')} className="shortcode-btn bg-blue-700 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'code'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{booking start date}}', 'defaultMessage')} className="shortcode-btn hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'booking start date'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{booking start time}}', 'defaultMessage')} className="shortcode-btn hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'booking start time'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{booking end time}}', 'defaultMessage')} className="shortcode-btn hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'booking end time'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{booking end date}}', 'defaultMessage')} className="shortcode-btn hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'booking end date'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{company name}}', 'defaultMessage')} className="shortcode-btn hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'company name'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{company id}}', 'defaultMessage')} className="shortcode-btn hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'company id'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{booking id}}', 'defaultMessage')} className="shortcode-btn hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'booking id'}
                </button>
              </div>
            </div>
          </div>

          {/* {userData && userData.role === 'Company Admin' && (
            <div className="grid grid-cols-1 md:grid-cols-3 gap-3 mb-2 mt-4">
              <div className='w-full'>
                <label htmlFor="firstName" className=" text-md mb-2" style={{fontSize:'14px', color:'#010808', lineHeight:'20px', letterSpacing:'0%'}}>
                  First Name
                </label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    placeholder='None'
                    value={userData.firstName}
                    className={`mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                  />
              </div>
              <div className='w-full'>
                <label htmlFor="lastName" className=" text-md mb-2" style={{fontSize:'14px', color:'#010808', lineHeight:'20px', letterSpacing:'0%'}}>
                  Last Name
                </label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    placeholder='None'
                    value={userData.lastName}
                    // onChange={(e) => setLastName(e.target.value)}
                    className={`mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                  />
              </div>

              <div className='w-full'>
                <label htmlFor="lastName" className=" text-md mb-2" style={{fontSize:'14px', color:'#010808', lineHeight:'20px', letterSpacing:'0%'}}>
                  Role
                </label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    placeholder='None'
                    value={userData.role}
                    className={`mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                  />
              </div>
            </div>
          )} */}

          <div className="flex md:flex-row md:justify-end flex-col pt-10 gap-3">
            <button onClick={closeModal} className="px-3 py-2 text-gray-600 border focus:outline-none focus:ring focus:border-gray-300">
              Discard
            </button>
            <button type="submit" className="py-2 px-3 text-white bg-black hover:bg-teal-600 focus:outline-none focus:ring focus:border-teal-300">
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SettingsModal;
