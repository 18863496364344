import React, { useState } from 'react';
import { CheckIcon } from '@heroicons/react/24/outline';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';

const RepeatAppointmentPopup = ({ closePopup, selectedRecurringOption, setSelectedRecurringOption, endDate, setEndDate, bookingStartDate, calculateRepeatCount }) => {
  const basicOptions = ['Never', 'Every week', 'Every 2 weeks', 'Every 4 weeks'];
  const [endDateError, setEndDateError] = useState('');
  const advancedOptions = [
    'Every 3 weeks',
    'Every 5 weeks',
    'Every 6 weeks',
    'Every 7 weeks',
    'Every 8 weeks',
    'Every 9 weeks',
    'Every 10 weeks',
    'Every 3 months (12 weeks)',
    'Every 4 months (16 weeks)',
    'Every 5 months (20 weeks)',
    'Every 6 months (24 weeks)',
    'Every year',
  ];

  const handleDateChange = (date) => {
    if (date) {
      setEndDateError('');
    }
    setEndDate(date);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 p-6 pr-10 flex items-center justify-center z-1000 text-gray-700">
      <div className="bg-gray-100 p-4 rounded-xl shadow-lg sm:w-96">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-xl font-semibold">Repeat appointment</h3>
          <button onClick={closePopup} className="text-gray-500 hover:text-gray-800">
            ✕
          </button>
        </div>

        <div className="space-y-4 mb-6">
          {basicOptions.map((option, index) => (
            <div
              key={index}
              className={`flex justify-between items-center border-b cursor-pointer text-lg pb-1 ${
                selectedRecurringOption === option ? 'border-gray-200' : 'border-gray-100'
              } hover:border-black`}
              onClick={() => setSelectedRecurringOption(option)}
            >
              <span>{option}</span>
              {selectedRecurringOption === option && (
                <CheckIcon className="w-5 h-5 text-blue-500" />
              )}
            </div>
          ))}
        </div>

        <div className="mt-1">
          <select
            className="w-full py-2 text-lg focus:ring"
            value={selectedRecurringOption}
            onChange={(e) => setSelectedRecurringOption(e.target.value)}
          >
            <option value="">More option</option>
            {advancedOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>

        <div className="mt-3 bg-white p-4 rounded-xl shadow-lg flex flex-row justify-between items-center">
          <h4 className="text-md font-medium mb-2">Ends after <span className="text-red-500">*</span></h4>
          <DatePicker
            selected={endDate}
            onChange={handleDateChange}
            minDate={new Date()}
            placeholderText="Select end date"
            className="w-full p-2 border rounded-md"
          />
        </div>

        {endDateError && (
          <div className="text-red-500 text-center mt-2">{endDateError}</div>
        )}

        {endDate && bookingStartDate && selectedRecurringOption && (
          <span className="pt-4 flex justify-center items-center text-md text-center text-gray-600">
            Appointment repeats {calculateRepeatCount(bookingStartDate, endDate, selectedRecurringOption)} times
          </span>
        )}

        <div className="flex justify-between mt-6">
          <button
            onClick={closePopup}
            className="px-4 py-2 bg-white border rounded-full border-gray-300 text-gray-600 hover:bg-gray-100"
          >
            Cancel
          </button>
          <button
            onClick={closePopup}
            className="px-4 py-2 bg-blue-600 text-white hover:bg-blue-700 rounded-full"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default RepeatAppointmentPopup;
