/* eslint-disable no-undef */
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setUser } from '../../actions';
import { signupApi } from '../../js/api';
import photos from '../../Assets/Group 1.png';

const Signup = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('user');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSignup = async (event) => {
    event.preventDefault();

    if (!username || !email || !password) {
      setError('Please fill in all required fields.');
      return;
    }
  
    try {
      setLoading(true);
      const user = await signupApi(username, email, password, role);
      dispatch(setUser(user));
      history.push('/login');
    } catch (error) {
      setError('Signup failed: ' + error.message);
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <div className="bg-gray-100 min-h-screen flex items-center justify-center">
      <div className="items-center">
        <div 
          className="flex flex-row items-center justify-center gap-3 px-4 py-2 mb-8 text-white">
            <img
              src={photos}
              alt="User"
              className="rectangle-full object-cover"
            />
        </div>
          <h1 className="text-3xl mb-6 text-black">Create Your Account</h1>
          <p className='text-black text-center mb-6'>Welcome back! please enter your details</p>
          <form onSubmit={handleSignup}>
            <div className="field">
              <label className=" text-gray-700 mb-2">Name</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="field">
              <label className="text-gray-700 mb-2">Email</label>
              <div className="control">
                <input
                  className="input"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="field">
              <label className="text-gray-700 mb-2">Password</label>
              <div className="control">
                <input
                  className="input text-gray-500"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
            </div>
            {error
            
            && (
            <div className="notification is-danger">
                {error}
              </div>
            
            )}
            <div className="field">           
            <div className="field">
              <div className="control">
                <button className="button is-primary" type="submit" disabled={loading}>
                  {loading ? (
                    <span>
                      <FontAwesomeIcon icon={faSpinner} spin /> Signup...
                    </span>
          ) : (
          
            'Signup'
          )}
                </button>
              </div>
            </div>
              <p className="text-center text-gray-800 pt-6">
                Already have an account?&nbsp;
                <NavLink
                  to="/login"
                  className="focus:shadow-outline appearance-none rounded border py-2 px-3 leading-tight bg-lime-400 text-slate-50 hover:bg-blue-500 focus:outline-none self-end"
                >
                  Login
                </NavLink>
              </p>
            </div>
          </form>      
      </div>
    </
    
    div>
  );

};

export default Signup;
