import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import edit from '../Assets/edit-05.png';
import trash from '../Assets/trash.svg';
import { useSwipeable } from 'react-swipeable';
import { useLocation } from 'react-router-dom';
import NewBookingForm from './NewBooking';
import SMSModal from './SmsModal';
import SmsLogModal from './SmsLog';
import './styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { CSVLink } from 'react-csv';
import icon from '../Assets/iconS.svg';
import sms from '../Assets/sms1.svg';
import icon3 from '../Assets/filter.png';
import download from '../Assets/download.svg';
import plus from '../Assets/plus.svg';
import search from '../Assets/search.svg';
import { MdArrowUpward, MdArrowDownward } from 'react-icons/md';
import { usePhoneNumberFormat } from './Provided';
import Sidebar from './SideBar';

const SortIcon = ({ isSortedDesc }) => {
  return isSortedDesc ? <MdArrowDownward /> : <MdArrowUpward />;
};

const customStyles = {
  rows: {
    style: {
      minHeight: '52px',
    },
  },
  headCells: {
    style: {
      backgroundColor: '#F9FAFB',
      paddingLeft: '8px',
      paddingRight: '8px',
      whiteSpace: 'nowrap', 
    },
  },
  cells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
      whiteSpace: 'normal',
    },
  },
};

const Bookings = ({setIsMenuOpen}) => {
  const history = useHistory();
  const [bookings, setBookings] = useState([]);
  const [activeTab, setActiveTab] = useState('current');
  const [filter, setFilter] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [newBookingsCount, setNewBookingsCount] = useState(0);
  const [selectedBookingData, setSelectedBookingData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBookings, setSelectedBookings] = useState(null);
  const location = useLocation();
  const [responseMessage, setResponseMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(true);
  const [companyDetails, setCompanyDetails] = useState([]);
  const [userData, setUserData] = useState(null);
  const { isInternational } = usePhoneNumberFormat();
  const [errorMessage, setErrorMessage] = useState('');
  const [isSmsLogModalOpen, setIsSmsLogModalOpen] = useState(false); 
  const [showNext24Hours, setShowNext24Hours] = useState(false);

  const handleOpenSmsLogModal = (phoneNumber) => {
    console.log("Selected phone number:", phoneNumber);
    history.push(`/admin/sms?phone=${encodeURIComponent(phoneNumber)}`);
  };

  useEffect(() => {
    const userDataFromStorage = localStorage.getItem('userData');
    if (userDataFromStorage) {
      const parsedUserData = JSON.parse(userDataFromStorage);
      setUserData(parsedUserData);
    }
  }, []);
  
  const storedRole = localStorage.getItem('role');
  const storedCompany = localStorage.getItem('company');

  const useWindowSize = () => {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);  
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    return size;
  };
  
  const [height, width] = useWindowSize();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const selectedTab = searchParams.get('selectedTab');
    setShowNext24Hours(searchParams.get('selectedTab') === 'next');
    if (selectedTab && (selectedTab === 'next')) {
      setActiveTab(selectedTab);
    }
  }, [location]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const filterFromQuery = searchParams.get('company');
    if (filterFromQuery) {
      setFilter(filterFromQuery);
      console.log('Filter set from query:', filterFromQuery);
    }
  }, [location.search]);

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const response = await axios.get('https://entro-api.onrender.com/api/getcompanydetails');
        if (response.data) {
          setCompanyDetails(response.data);
        }
      } catch (error) {
        console.error('Error fetching company details:', error);
      }
    };
    fetchCompanyDetails();
  }, []);

  const findCompanyIndex = (bookingCompany) => {
    const index = companyDetails.findIndex(detail => detail.company === bookingCompany);
    return index >= 0 ? index : null;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;
        let data;
  
        if (storedRole === 'System Admin') {
          response = await axios.get('https://entro-api.onrender.com/api/getbooking');
          console.log(response, "response data");
          data = response.data.map(booking => ({
            ...booking,
            bookingCustomerPhone: formatPhoneNumber(booking.bookingCustomerPhone, isInternational)
          }));
        } else if (storedRole === 'Company User') {
          response = await axios.get('https://entro-api.onrender.com/api/getbooking');
          data = response.data.filter(booking => booking.company === storedCompany);
        } else if (storedRole === 'Company Admin') {
          response = await axios.get('https://entro-api.onrender.com/api/getbooking');
          data = response.data.filter(booking => booking.company === storedCompany)
                               .map(booking => ({
                                 ...booking,
                                 bookingCustomerPhone: formatPhoneNumber(booking.bookingCustomerPhone, isInternational)
                               }));
        }
        setBookings(data);
      } catch (error) {
        console.error('Failed to fetch bookings:', error);
      }
    };
  
    fetchData();
  }, [isInternational]);

  const formatPhoneNumberFromApi = (phoneNumber) => {
    const cleaned = ('' + phoneNumber).replace(/[^\d]/g, '');
    // const countryCode = cleaned.slice(0, 3);
    const partOne = cleaned.slice(3, 6);
    const partTwo = cleaned.slice(6, 10);
    const remainder = cleaned.slice(10);
    return `${partOne} ${partTwo} ${remainder}`;
  };

  const formatPhoneNumber = (phone, formatIntl) => {
    const cleaned = ('' + phone).replace(/[^\d]/g, '');
    const partOne = cleaned.slice(0, 3);
    const partTwo = cleaned.slice(3, 7);
    const partThree = cleaned.slice(3,6)
    const remainder = cleaned.slice(7);
    const remainder2 = cleaned.slice(6);
  
    if (!formatIntl) {
      return `${partOne} ${partTwo} ${remainder}`;
    }
    console.log('Formatting phone:', phone);
    if (cleaned.length > 7) {
      return `+354 ${partThree} ${remainder2}`;
    } else if (cleaned.length < 6) {
      return ``;
    } else {
      return `+354 ${partOne} ${partTwo} ${remainder}`;
    }
  };
  
  const diacriticChars = {
    'à': 'a', 'á': 'a', 'â': 'a', 'ã': 'a', 'ä': 'a', 'å': 'a', 'æ': 'ae',
    'ç': 'c', 'è': 'e', 'é': 'e', 'ê': 'e', 'ë': 'e',
    'ì': 'i', 'í': 'i', 'î': 'i', 'ï': 'i',
    'ð': 'd', 'ñ': 'n',
    'ò': 'o', 'ó': 'o', 'ô': 'o', 'õ': 'o', 'ö': 'o',
    'ø': 'o', 'œ': 'oe',
    'ß': 'ss',
    'ù': 'u', 'ú': 'u', 'û': 'u', 'ü': 'u',
    'ý': 'y', 'ÿ': 'y',
    'ā': 'a', 'ă': 'a', 'ą': 'a', 'ć': 'c', 'č': 'c', 'ç': 'c',
    'ď': 'd', 'đ': 'd', 'ē': 'e', 'ĕ': 'e', 'ė': 'e', 'ę': 'e', 'ě': 'e',
    'ğ': 'g', 'ġ': 'g', 'ģ': 'g', 'ħ': 'h', 'ĩ': 'i', 'ī': 'i', 'ĭ': 'i', 'į': 'i', 'ı': 'i',
    'ĳ': 'ij', 'ķ': 'k', 'ĸ': 'k', 'ĺ': 'l', 'ļ': 'l', 'ľ': 'l', 'ŀ': 'l', 'ł': 'l',
    'ń': 'n', 'ņ': 'n', 'ň': 'n', 'ŋ': 'n', 'ō': 'o', 'ŏ': 'o', 'ő': 'o', 'œ': 'oe',
    'ŕ': 'r', 'ŗ': 'r', 'ř': 'r', 'ś': 's', 'š': 's', 'ŝ': 's', 'ş': 's', 'ș': 's', 'ß': 'ss',
    'ť': 't', 'ţ': 't', 'ț': 't', 'ŧ': 't', 'ũ': 'u', 'ū': 'u', 'ŭ': 'u', 'ů': 'u', 'ű': 'u', 'ų': 'u',
    'ŵ': 'w', 'ŷ': 'y', 'ź': 'z', 'ż': 'z', 'ž': 'z',
    'í': 'i', 'Í': 'i',
    'á': 'a', 'Á': 'a',
  };
  
  const normalizeString = (str) => {
    if (!str) return '';
  
    return str
      .split('')
      .map((char) => diacriticChars[char] || char)
      .join('')
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/\s+/g, ' ')
      .trim();
  };

  const filteredBookings = () => {
    const now = new Date().getTime();
    const twentyFourHoursFromNow = now + 24 * 60 * 60 * 1000;
  
    let filteredByTab = bookings.filter((booking) => {
      const bookingStartDate = new Date(`${booking.bookingStartDate} ${booking.bookingStartsAtTime}`);
      const bookingEndDate = new Date(`${booking.bookingEndDate} ${booking.bookingEndsAtTime}`);
      const startsAt = bookingStartDate.getTime();
      const endsAt = bookingEndDate.getTime();
  
      if (showNext24Hours && activeTab === 'next') {
        return startsAt >= now && startsAt <= twentyFourHoursFromNow;
      } else {
        return (
          (activeTab === 'current' && startsAt <= now && now <= endsAt) ||
          (activeTab === 'past' && now > endsAt) ||
          (activeTab === 'next' && startsAt >= now)
        );
      }
    });
  
    if (filter && filter.length >= 3) {
      const normalizedFilter = normalizeString(filter).replace(/\s+/g, '');
      const filterWords = normalizedFilter.split(' ');
  
      filteredByTab = filteredByTab.filter((booking) => {
        const searchText = normalizeString(
          `${booking.bookingCustomerName} ${booking.bookingCustomerPhone} ${formatPhoneNumber(booking.bookingCustomerPhone, isInternational)} ${formatPhoneNumberFromApi(booking.bookingCustomerPhone)} ${booking.bookingCode} ${booking.bookingCompanyEmail} ${booking.company} ${booking.Connection || booking.Integration} ${booking.status} ${booking.bookingdescription || ''}`
        ).replace(/\s+/g, '');
  
        return filterWords.every((word) => searchText.includes(word));
      });
    }
  
    return filteredByTab;
  };       

  const sendSMSReminder = async (phoneNo, message, company_ID,userId ) => {
    const cleanedPhoneNo = '+' + phoneNo.replace(/\s+/g, '').replace(/^\+/, '');
    const payload = {
      userId,
      phoneNo: cleanedPhoneNo,
      body: message,
      company_ID,
      integration:"web",
    };
    console.log(payload);
  
    try {
      const response = await axios.post('https://smsengine.onrender.com/api/smsapi', payload);
      const status = response.data.status;
      
      if (response.status === 200) {
        if (status.status === 400 && status.code === 21212) {
          setErrorMessage(`Failed to send SMS: ${status.moreInfo}`);
          setTimeout(() => {
            setErrorMessage('');
          }, 5000);
        } else if ((status === "CompanyID dose not exist")) {
          setErrorMessage(`Failed to send SMS: ${status}`);
          setTimeout(() => {
            setErrorMessage('');
          }, 5000);
        } else {
          setResponseMessage('SMS reminder sent successfully!');
          setTimeout(() => {
            setResponseMessage('');
          }, 5000);
        }
      } else {
        throw new Error('Unexpected response status');
      }
    } catch (error) {
      console.error('Failed to send SMS:', error);
      setErrorMessage(`Failed to send SMS reminder. ${error.message}`);
      setTimeout(() => {
        setErrorMessage('');
      }, 5000);
    }
  };
  
  const RowActions = ({ onDelete, row }) => {
    return (
      <div className="flex flex-row text-gray-600">
        <img src={trash} alt="Delete" className="w-5 h-5 object-cover cursor-pointer mr-2" onClick={(event) => onDelete(row.bookingId, event)} />
        <button className="text-red-500 mr-4 cursor-pointer" onClick={(event) => onDelete(row.bookingId, event)}>Delete</button>
      </div>
    );
  };

  const RowEdit = ({ onEditBooking, row }) => {
    return (
      <div className="flex flex-row text-gray-600">
        <img 
          src={edit} alt="Edit" 
          className="w-5 h-5 object-cover cursor-pointer mr-2" 
          onClick={() => onEditBooking(row.bookingId)} 
        />
        <button 
          className="hover:underline cursor-pointer" 
          onClick={() => onEditBooking(row.bookingId)}
        >
          Edit
        </button>
      </div>
    );
  };

  const getColumns = () => {
    const baseColumns = [
        { 
          name: 'ID', 
          selector: row => row.bookingId, 
          sortable: true,
          sortIcon: <SortIcon />,
          omit: window.innerWidth < 768
        },
        { 
          name: 'Customer', 
          selector: row => row.bookingCustomerName, 
          sortable: true, 
          sortIcon: <SortIcon />,
          format: row => (
            <div className="whitespace-normal sm:whitespace-nowrap overflow-hidden text-ellipsis">
              <div 
                className="font-bold text-black pb-1 cursor-pointer"
              >
                {row.bookingCustomerName}
              </div>
              <div className="text-sm">{row.bookingCompanyEmail}</div>
            </div>
          ),
          grow: 4,
          wrap: true
        },
        {
          name: 'Phone Number',
          selector: row => row.bookingCustomerPhone,
          sortable: true,
          grow: 2,
          wrap: true,
          cell: row => {
            const endsAt = new Date(`${row.bookingEndDate} ${row.bookingEndsAtTime}`).getTime();
            const now = new Date().getTime();
            const tolerance = 10 * 60 * 1000;
            const phoneNumberField = now > endsAt + tolerance ? row.bookingCustomerPhone : row.bookingCustomerPhoneLocal;
            const formattedPhone = formatPhoneNumber(phoneNumberField, isInternational);
            const formatedPhoneSystem = formatPhoneNumberFromApi(row.bookingCustomerPhone);
            return (
              <span
                style={{ color: '#349999', cursor: 'pointer' }}
                onClick={() => {
                  handlePhoneNumberClick(row);
                  setIsModalOpen(true);
                }}
                className="hover:underline"
              >
                {storedRole === 'Company User'? formatedPhoneSystem : formattedPhone}
              </span>
            );
          }
        },    
        {
          name: 'Description',
          selector: row => row.bookingdescription || '',
          sortable: true,
          grow: 4,
          wrap: true,
          sortFunction: (rowA, rowB) => {
            const descA = rowA.bookingdescription;
            const descB = rowB.bookingdescription;

      
            if (!descA && !descB) return 0;
            if (!descA) return 1;
            if (!descB) return -1;

            const lowerA = descA.toLowerCase();
            const lowerB = descB.toLowerCase();

            if (lowerA < lowerB) return -1;
            if (lowerA > lowerB) return 1;
            return 0;
          },
          cell: row => {
            const truncatedText =
              row.bookingdescription && row.bookingdescription !== "null"
                ? row.bookingdescription.slice(0, 20) + (row.bookingdescription.length > 20 ? '...' : '')
                : '';
            return (
              <div className="tooltip-container">
                <span className="truncated-text">{truncatedText}</span>
                {truncatedText.endsWith('...') && (
                  <div className="tooltip-content">
                    {row.bookingdescription}
                  </div>
                )}
              </div>
            );
          },
        },
        {
          name: 'Origin',
          selector: row => row.Integration || '',
          sortable: true,
          grow: 2,
          wrap: true,
          sortFunction: (rowA, rowB) => {
            const originA = rowA.Integration;
            const originB = rowB.Integration;
        
            if (!originA && !originB) return 0;
            if (!originA) return 1;
            if (!originB) return -1;
        
            const lowerA = originA.toLowerCase();
            const lowerB = originB.toLowerCase();
        
            if (lowerA < lowerB) return -1;
            if (lowerA > lowerB) return 1;
            return 0;
          },
          cell: row => {
            const truncatedText =
              row.Integration && row.Integration !== "null"
                ? row.Integration.slice(0, 10) + (row.Integration.length > 10 ? '...' : '')
                : '';
            return (
              <div className="tooltip-container">
                <span className="truncated-text">{truncatedText}</span>
                {truncatedText.endsWith('...') && (
                  <div className="tooltip-content">
                    {row.Integration}
                  </div>
                )}
              </div>
            );
          },
        },
        {
          name: 'Code',
          selector: row => row.bookingCode,
          sortable: true,
          sortIcon: <SortIcon />,
          cell: row => <span style={{ color: '#349999', cursor: 'pointer' }} onClick={() => handleCodeClick(row.bookingCode)} className="hover:underline">{row.bookingCode}</span>
        },
        { 
          name: 'Valid From', 
          selector: row => {
            const startDate = row.bookingStartDate.replace(/\//g, '-');
            return `${startDate} ${row.bookingStartsAtTime}`;
          }, 
          sortable: true, 
          sortIcon: <SortIcon />,
          grow: 3.5,
          wrap: true
        },
        {
          name: 'Valid To', 
          selector: row => {
            const endDate = row.bookingEndDate.replace(/\//g, '-');
            return `${endDate} ${row.bookingEndsAtTime}`;
          }, 
          sortable: true, 
          sortIcon: <SortIcon />,
          grow: 3.5,
          wrap: true
        },
        {
          name: 'SMS Log',
          cell: row => (
            <button onClick={() => handleOpenSmsLogModal(row.bookingCustomerPhone)}>
              <img src={sms} alt="View SMS Log" />
            </button>

          ),
        }, 
        {
          name: 'Actions',
          cell: row => <RowActions onDelete={handleDelete} row={row} />,
        },
        {
          name: '',
          cell: row => <RowEdit onEditBooking={handleEditBooking} row={row} />,
        }
    ];

    if (userData && userData.role === 'System Admin') {
      baseColumns.splice(6, 0, { name: 'Company', selector: row => row.company, sortable: true });
    }
    
    return baseColumns;
  };

  const columns = getColumns();
  const headers = [
    { label: "ID", key: "bookingId" },
    { label: "Name", key: "bookingCustomerName" },
    { label: "Phone Number", key: "bookingCustomerPhone" },
    { label: "Code", key: "bookingCode" },
    { label: "Valid From", key: "bookingStartDate" },
    { label: "Valid To", key: "bookingEndDate" },
    { label: "Email", key: "bookingCompanyEmail" },
    { label: "Company", key: "company" },
    { label: "Origin", key: "origin" },
    { label: "Status", key: "status" }
  ];

  const csvData = filteredBookings().map(booking => ({
    ...booking,
    origin: booking.Connection || booking.Integration,
  }));

  const handleCodeClick = async (code) => {
    history.push(`/admin/events/${code}`);
    setFilter(code);
  };

  const handleDelete = async (bookingId, event) => {
    event.preventDefault();
    if (!bookingId) {
      alert('Invalid booking ID');
      return;
    }
  
    try {
        const response = await axios.post('https://entro-api.onrender.com/api/deletebooking', { bookingId });
        if (response.status === 200) {
            const newBookings = bookings.filter(booking => booking.bookingId !== bookingId);
            setBookings(newBookings);
        } else {
            const errorMessage = response.data.error || 'Unknown error occurred while deleting booking';
            console.error('Error deleting booking:', errorMessage);
            alert(errorMessage);
        }
    } catch (error) {
      console.error('Error deleting booking:', error);
      alert('Failed to delete booking: ' + error.message);
    }
  };
  
  const handleNewBookingAdded = () => {
    setNewBookingsCount((prevCount) => prevCount + 1);
    console.log('New booking added! Total count:', newBookingsCount + 1);
  };

  const handleEditBooking = (bookingId) => {
    const bookingToEdit = bookings.find(booking => booking.bookingId === bookingId);
    if (bookingToEdit) {
      setSelectedBookingData(bookingToEdit);
      setShowModal(true);
    } else {
      console.error("No booking found with ID:", bookingId);
    }
  };

  const [buttonBackgroundColor, setButtonBackgroundColor] = useState('#349999');
  const [selectedBooking, setSelectedBooking] = useState('Active Bookings');
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setSelectedBooking(`${tab.charAt(0).toUpperCase() + tab.slice(1)} Bookings`);

    switch (tab) {
      case 'current':
        setButtonBackgroundColor('#349999');
        setSelectedBooking(`Active Bookings`);
        break;
      case 'next':
        setButtonBackgroundColor('#349999');
        break;
      case 'past':
        setButtonBackgroundColor('#349999');
        break;
      case 'new':
        setButtonBackgroundColor('#349999');
        break;
      default:
        setButtonBackgroundColor('#349999');
    }
  };

  const handleFilterChange = (value) => {
    setFilter(value);
  };

  const openModal = () => {
    setSelectedBookingData(null);
    setShowModal(true);
  };

  const updateTableData = (updatedBookingData) => {
    setBookings(currentBookings => {
      return currentBookings.map(booking => {
        if (booking.bookingId === updatedBookingData.bookingId) {
          return { ...booking, ...updatedBookingData };
        }
          return booking;
        });
    });
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => setIsMenuOpen(false),
    onSwipedRight: () => setIsMenuOpen(true),
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
    trackMouse: false
  });

  const handleAddBooking = (newBookings) => {
    setBookings(currentBookings => [...currentBookings, newBookings]);
  };

  const handlePhoneNumberClick = (booking) => {
    const index = findCompanyIndex(booking.company);
    if (index !== null) {
      console.log(`Using index ${index} for company ${booking.company}`);
      setSelectedBookings(booking);
    } else {
      console.error('No matching company found for:', booking.company);
    }
  };

  return (
    <div className={`container control md:pt-8`}>
      <div {...handlers} className="pb-2 md:px-8">
        <div className='md:flex md:flex-row lg:flex lg:flex-row items-center gap-3 hidden' style={{color:"#010808"}}>
          <img
            src={icon}
            alt="User"
            className=" bg-gray-100"
          />
          <FontAwesomeIcon icon={faChevronRight} className="" />
          <span className='font-bold' style={{lineHeight:'20px', fontSize:'14px'}}>Bookings</span>
        </div>

        <div className="bg-gray-100 pt-0 md:pt-6 flex flex-col items-start lg:flex-row lg:items-center lg:justify-between md:flex-row md:items-center md:justify-between" style={{color:"#010808"}}>
          <div className="flex flex-col pb-4 md:pb-0">
            <span className="font-bold" style={{fontSize:'36px', color:'#010808', lineHeight:'44px', letterSpacing:'2%'}}>Bookings</span>
            <p className="text-gray-600 text-base" style={{fontSize:'16px', lineHeight:'24px', letterSpacing:'0%'}}>View all your existing bookings, or add new.</p>
          </div>
        
          <div className="flex flex-col">
            <div className="flex flex-col items-start pt-2 lg:flex-row lg:items-center md:flex-row md:items-center gap-2">
              <CSVLink
                data={csvData}
                headers={headers}
                filename="all_bookings.csv"
                className="custom-widthB flex items-center justify-center gap-2 border bg-white border-gray-300 md:px-5 md:w-1/2 py-2 md:mr-0"
                style={{ color:'#010808' }}
              >
                <img src={download} alt="Download" className="" />
                <span className='font-bold' style={{fontSize:'18px', lineHeight:'28px', color:'010808', letterSpacing:'0%'}}>Export</span>
              </CSVLink>

              <button onClick={openModal} className="flex items-center justify-center gap-2 border text-white bg-black border-gray-300 w-full md:px-2 py-2">
                <img
                  src={plus}
                  alt="User"
                  className=""
                />
                <span className='font-bold'  style={{fontSize: 'large', lineHeight:'28px',color:'010808', letterSpacing:'0%'}}>New Booking</span>
              </button>
              </div>
          </div>
        </div>
      <div className="custom-widthB flex flex-row justify-between pt-6 md:pb-2 py-6">
        <div>
          <button
            className={`button ${activeTab === 'current' ? 'is-active' : ''} m-1`}
            onClick={() => handleTabChange('current')}
            style={{
              backgroundColor: activeTab === 'current' ? '#349999' : '#FFFFFF',
              color: activeTab === 'current' ? '#FFFFFF' : '#737878',
              border: '1px solid #FFFFFF',
              borderRadius: '0px',
            }}
          >
            Active
          </button>
          <button
            className={`button ${activeTab === 'next' ? 'is-active' : ''} m-1`}
            onClick={() => handleTabChange('next')}
            style={{
              backgroundColor: activeTab === 'next' ? '#349999' : '#FFFFFF',
              color: activeTab === 'next' ? 'white' : '#737878',
              border: '1px solid #FFFFFF',
              borderRadius: '0px',
            }}
          >
            Next
          </button>
          <button
            className={`button ${activeTab === 'past' ? 'is-active' : ''} m-1`}
            onClick={() => handleTabChange('past')}
            style={{
              backgroundColor: activeTab === 'past' ? '#349999' : '#FFFFFF',
              color: activeTab === 'past' ? '#FFFFFF' : '#737878',
              border: '1px solid #FFFFFF',
              borderRadius: '0px',
            }}
          >
            Past
          </button>
        </div>

        <div className="hidden md:flex flex-row justify-end">
          <div className="relative">
            <img
              src={search}
              alt="User"
              className="absolute mt-5 pt-1 transform -translate-y-1/2 left-3 text-gray-500"
            />
            <input
              className="px-10 text-black py-2 border focus:outline-none"
              style={{ 
                '&:focus': {
                  borderColor: '#349999'
                },
              }}
              type="text"
              placeholder="Search by keyword"
              value={filter}
              onChange={(e) => handleFilterChange(e.target.value)}
            />
          </div>
        </div>
      </div>
        <div className="md:hidden relative">
          <div className="relative mr-2 mb-2">
            <img
              src={search}
              alt="User"
              className="absolute mt-5 pt-1 transform -translate-y-1/2 left-3 text-gray-500"
            />
            <input
              className="custom-widthB w-full text-gray-500 py-2 px-12 border focus:outline-none"
              type="text"
              placeholder="Search by Keyword"
              value={filter}
              onChange={(e) => handleFilterChange(e.target.value)}
            />          
          </div>
        </div>
      </div>

      <div className='overflow-x-auto md:mx-8'>
          <DataTable
            columns={columns}
            data={filteredBookings()}
            customStyles={customStyles}
            pagination
            persistTableHead
            highlightOnHover
            sortIcon={<SortIcon />}
          />
      </div>

      {showModal && (
          <NewBookingForm
            closeModal={() => setShowModal(false)}
            onNewBookingAdded={handleNewBookingAdded}
            editMode={selectedBookingData !== null}
            bookingData={selectedBookingData}
            onBookingUpdated={updateTableData}
            userData={userData}
            updateTableDataAdd={handleAddBooking}
          />
      )}

      <SMSModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSend={sendSMSReminder}
        responseMessage={responseMessage}
        isSuccess={isSuccess}
        booking={selectedBookings}
        setResponseMessage={setResponseMessage}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        setIsSuccess={setIsSuccess}
        companyDetails={companyDetails}
        bookings={bookings}
      />

      {isSmsLogModalOpen && (
        <SmsLogModal
          isOpen={isSmsLogModalOpen}
          onClose={() => {
            setIsSmsLogModalOpen(false);
            setSelectedBookings(null);
          }}
            booking={selectedBookings}
            storedRole={storedRole}
            storedCompany={storedCompany}
          />
      )}
    </div>
  );
};

export default Bookings;