import React, { useState, useEffect } from 'react';
import ReactSelect from 'react-select';
import DataTable from 'react-data-table-component';
import { MdArrowUpward } from 'react-icons/md';
import edit from '../Assets/edit-05.png';
import trash from '../Assets/trash.svg';
import iconA from '../Assets/iconA.png';
import avator2 from '../Assets/avator3.png';
import './styles.css';

const TableUser = ({ headings, rows, onDelete, onEditUser, onUpdateRole, userData }) => {
  const [editUserId, setEditUserId] = useState(null);
  const [selectedRole, setSelectedRole] = useState("");
  const storedRole = userData?.role;

  const useWindowSize = () => {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);

    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return size;
  };

  const [height, width] = useWindowSize();

  const rolesOptions = [
    { value: 'System Admin', label: 'System Admin' },
    { value: 'spacer', label: '', isDisabled: true },
    { value: 'Company User', label: 'Company User' },
    { value: 'spacer', label: '', isDisabled: true },
    { value: 'Company Admin', label: 'Company Admin' },
  ];

  const customStyle = {
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    option: (styles, { data, isDisabled }) => {
      return {
        ...styles,
        backgroundColor: isDisabled ? '#F9FAFB' : '#F9FAFB',
        color: isDisabled ? 'transparent' : 'black',
        cursor: isDisabled ? 'default' : 'pointer',
      };
    },
    control: styles => ({ ...styles, width: 156 }),
  };

  const handleRoleChange = (selectedOption, user) => {
    if (selectedOption.value === 'spacer') return;
    const newRole = selectedOption.value;
    const updatedUser = { ...user, role: newRole };
    onUpdateRole(updatedUser);
    setEditUserId(null);
  };

  const getFilteredRoles = () => {
    if (storedRole === 'Company Admin') {
      return rolesOptions.filter(role => role.value !== 'System Admin');
    }
    if (storedRole === 'Company User') {
      return rolesOptions.filter(role => role.value !== 'System Admin' && role.value !== 'Company Admin');
    }
    return rolesOptions;
  };

  if (typeof onUpdateRole !== 'function') {
    console.error('onUpdateRole is not a function. Please pass the correct prop.');
  }

  const displayNames = {
    firstName: 'Customer',
    lastName: 'Last Name',
    userId: 'User ID',
    email: 'Contact',
    username: 'Username',
    company: 'Company',
    role: 'Role',
    actions: 'Actions',
  };

  const customStyles = {
    rows: {
      style: {
        minHeight: '72px',
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
        backgroundColor: '#F9FAFB',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '12px',
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: row => row.role === 'System Admin',
      style: {
        backgroundColor: '#F7FFFF',
        color: '#000000',
      },
    },
  ];

  const columns = headings.map((heading) => {
    let growValue = heading === 'firstName' ? 1 : 0;
    let minWidthValue = heading === 'firstName' ? '200px' : '150px';

    if (storedRole === 'Company User') {
      growValue = heading === 'firstName' ? 0.75 : 0;
      minWidthValue = heading === 'firstName' ? '200px' : '150px';
    }

    // if (heading === 'company') {
    //   return {
    //     name: 'Company',
    //     selector: row => row[heading],
    //     cell: (row) => {
    //       const company = companies.find(c => c.company_ID === row.company_ID);
    //       return <span>{company ? company.company : 'Unknown'}</span>;
    //     }
    //   };
    // }

    if (heading === 'actions' || heading === 'Actions') {
      return {
        name: displayNames[heading],
        selector: row => row[heading],
        right: true,
        omit: false,
        cell: (row) => {
          const showActions = storedRole === 'System Admin' || storedRole === 'Company Admin' || (storedRole === 'Company User' && row.username === userData.username);
          if (!showActions) return null;

          if (heading === 'actions') {
            return (
              <div className="flex flex-row gap-1 text-gray-600 text-center ">
                <img src={trash} alt="User" className="rectangle-full w-5 h-5 object-cover" />
                <button onClick={() => onDelete(row['userId'])} className="text-red-500 pr-4">
                  Delete
                </button>
              </div>
            );
          }
          if (heading === 'Actions') {
            return (
              <div className="flex flex-row gap-1 text-gray-600  md:pr-6 text-center ">
                <img src={edit} alt="User" className="rectangle-full w-5 h-5 object-cover" />
                <button onClick={() => onEditUser(row)} className="hover:underline text-gray-600">
                  Edit
                </button>
              </div>
            );
          }
        },
      };
    }

    return {
      name: displayNames[heading],
      selector: row => row[heading],
      sortable: !['actions', 'Actions'].includes(heading),
      right: heading === 'actions',
      grow: growValue,
      minWidth: minWidthValue,
      omit: heading === 'userId' && window.innerWidth < 768,
      cell: (row) => {
        if (heading === 'firstName') {
          return (
            <div className='flex flex-row gap-3 items-center pr-12 lg:pr-0'>
              <img
                src={row.image || avator2}
                className='w-12 h-12'
                alt={`Profile of ${row.firstName}`}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = avator2;
                }}
              />
              <span className={`${row.role === 'System Admin' ? 'text-black font-bold' : 'text-black font-semibold'}`}>
                {`${row.firstName} ${row.lastName}`}
              </span>
              {row.role === 'System Admin' && (
                <img src={iconA} className='w-4 h-5' alt="Admin role icon" />
              )}
            </div>
          );
        }

        if (heading === 'role') {
          const roleStyle = row.role === 'System Admin'
            ? { backgroundColor: '#E6F7F7', color: '#205E5E', padding: '10px', borderRadius: '5px' }
            : row.role === 'Company User'
            ? { backgroundColor: '#EFF8FF', color: '#175CD3', padding: '10px', borderRadius: '5px' }
            : { padding: '10px' };

          return editUserId === row.userId ? (
            <ReactSelect
              options={getFilteredRoles(row.role)}
              styles={customStyle}
              onChange={(selectedOption) => handleRoleChange(selectedOption, row)}
              value={rolesOptions.find(option => option.value === row.role)}
              menuPortalTarget={document.body}
              menuPosition={'fixed'}
            />
          ) : (
            <div
              className='role-text text-gray-700'
              style={roleStyle}
              onClick={() => { setEditUserId(row.userId); setSelectedRole(row.role); }}
            >
              {row.role}
            </div>
          );
        }
        return row[heading];
      },
    };
  });

  return (
    <DataTable
      columns={columns}
      data={rows}
      sortIcon={<MdArrowUpward />}
      pagination
      persistTableHead
      highlightOnHover
      customStyles={customStyles}
      conditionalRowStyles={conditionalRowStyles}
    />
  );
};

export default TableUser;
