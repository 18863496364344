import React from 'react';
import { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import axios from 'axios';
import TableConnection from './TableConnection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { CSVLink } from 'react-csv';
import ConnectionForm from './AddConnection';
import icon from '../Assets/iconS.svg';
import download from '../Assets/download.svg';
import plus from '../Assets/plus.svg';
import search from '../Assets/search.svg';
import './styles.css';

const Connection = ({setIsMenuOpen}) => {
  const headings = ["Connection_ID", "Connection", "company", "SharedSecret", "status", "Actions", 'actions'];

  const [data, setData] = useState('');
  const [filter, setFilter] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [result, setResult] = useState([]);
  const [rows, setRows] = useState([]);
  const [selectedConnectionData, setSelectedConnectionData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://entro-api.onrender.com/api/getconnectiondetails');
        console.log('Response status:', response.status);
        console.log('Data fetched:', response.data);
        setData(response.data);
        setRows(response.data);
        setResult(response.data);
        const initialData = response.data;

        const filteredData = applyFilter(initialData, filter, headings);
        setResult(filteredData);

        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
        setRows(currentItems);
        setData(initialData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleDelete = async (Connection_ID) => {
    try {
      console.log('Dynamic unitId:', Connection_ID);
      const response = await axios.post('https://entro-api.onrender.com/api/deleteconnectiondetails', { Connection_ID });

      console.log('Response status:', response.status);
      console.log('Message:', response.data.msg);

      if (response.status === 200) {
        const updatedData = data.filter((connection) => connection.Connection_ID !== Connection_ID);
        setData(updatedData);

        const filteredData = applyFilter(updatedData, filter, headings);
        setResult(filteredData);

        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
        setRows(currentItems);
      } else {
        console.error('Error deleting company:', response.data.error);
      }
    } catch (error) {
      console.error('Error deleting company:', error);
    }
  };

  const normalizeAndReplace = (input) => {
    let normalized = input.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    normalized = normalized.replace(/æ/g, 'ae').replace(/œ/g, 'oe');
    normalized = normalized.replace(/ß/g, 'ss');
    normalized = normalized.replace(/ð/g, 'd');
    return normalized;
  };
  
  const applyFilter = (data, filter, headings) => {
    if (filter.length >= 3) {
      const normalizedFilter = normalizeAndReplace(filter);
      const filtered = data.filter((row) => {
        return headings.some((heading) => {
          const rowValue = normalizeAndReplace(String(row[heading] || ''));
          if (heading === 'status') {
            return rowValue === normalizedFilter;
          }
          return rowValue.includes(normalizedFilter);
        });
      });
      return filtered;
    } else {
      return data;
    }
  };
  
  const handleFilterChange = (value) => {
    setFilter(value);
    if (value.length >= 3) {
      const filteredData = applyFilter(data, value, headings);
      setRows(filteredData);
    } else {
      setRows(data);
    }

    // const indexOfLastItem = currentPage * itemsPerPage;
    // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    // const currentItems = result.slice(indexOfFirstItem, indexOfLastItem);
    // setRows(currentItems);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedConnectionData(null);
  };

  const handleEditConnection = (connectionData) => {
    setSelectedConnectionData(connectionData);
    setShowModal(true);
  };

  const updateTableData = (updatedConnectionData) => {
    const updatedData = data.map(connection => {
      if (connection.Connection_ID === updatedConnectionData.Connection_ID) {
        return updatedConnectionData;
      }
      return connection;
    });
    setData(updatedData);
    
    const filteredData = applyFilter(updatedData, filter, headings);
    setResult(filteredData);
  
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
    setRows(currentItems);
  };
  
  const handleAddConnection = async (newConnectionData) => {
    try {
      const response = await axios.post('https://entro-api.onrender.com/api/addconnectiondetails', newConnectionData);

      if (response.status === 200) {
        const newDeviceData = response.data.result;
        console.log(newDeviceData);
        const updatedRows = [newDeviceData, ...rows];
        setRows(updatedRows);
      } else {
        console.error('Failed to add connection:', response.data.error);
      }
    } catch (error) {
      console.error('Error adding connection:', error);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => setIsMenuOpen(false),
    onSwipedRight: () => setIsMenuOpen(true),
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
    trackMouse: false
  });
  
  return (
    <div className="container control pt-0 md:pt-8">
      <div {...handlers} className="pb-2 md:px-8">
        <div className='md:flex md:flex-row lg:flex lg:flex-row items-center gap-3 hidden' style={{color:"#010808"}}>
          <img
            src={icon}
            alt="User"
            className=""
          />
          <FontAwesomeIcon icon={faChevronRight} className="" />
          <span className='font-bold'>Connections</span>
        </div>

        <div className="bg-gray-100 flex flex-col items-start md:flex-row md:items-center md:justify-between">
          <div className="flex flex-col pb-8 lg:pt-0">
            <span className="font-bold" style={{fontSize:'36px', color:'#010808', lineHeight:'44px', letterSpacing:'2%'}}>Connections</span>
            <p className="text-gray-600 text-base" style={{fontSize:'16px', lineHeight:'24px', letterSpacing:'0%'}}>Below all of your Connections are listed.</p>
          </div>

          <div className="flex flex-col mt-0 md:mt-4">
            <div className="flex flex-col items-start lg:flex-row lg:items-center md:flex-row md:items-center md:justify-end gap-2">
              <CSVLink
                data={data}
                headers={headings}
                filename={'connection.csv'}
                className="custom-width flex items-center justify-center  gap-2 border bg-white border-gray-300 md:px-3 py-2" style={{ color: "#010808" }}
              >
                <img
                  src={download}
                  alt="User"
                  className=""
                />
                <span className='font-bold' style={{ fontSize: '18px', lineHeight: '28px', color: '#010808', letterSpacing: '0%' }}>Export</span>
              </CSVLink>

              <button 
                onClick={openModal} 
                className="flex items-center justify-center gap-2 border text-white  border-gray-300 w-full md:w-auto py-2 md:px-3" 
                style={{ backgroundColor: '#010808' }}
              >
                <img
                  src={plus}
                  alt="User"
                  className=""
                />
                <span className='font-bold' style={{ fontSize: '18px', lineHeight: '28px', backgroundColor: '#010808', letterSpacing: '0%' }}>New Connection</span>
              </button>
            </div>

            <div className="custom-widthl relative mt-6 md:mt-0">
              <span className="text-black hidden lg:block md:block pt-6">Search</span>
              <img
                  src={search}
                  alt="User"
                  className="absolute mt-5 pt-1 transform -translate-y-1/2 left-3 text-gray-500"
                />
              <input
                className="w-full px-10 text-gray-500 py-2 border rounded focus:outline-none"
                type="text"
                placeholder="Search by keyword"
                value={filter}
                onChange={(e) => handleFilterChange(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mb-2 mt-8 overflow-x-auto md:px-8">
        <TableConnection 
          headings={headings} 
          rows={rows} 
          onDelete={handleDelete} 
          onEditConnection={handleEditConnection} 
          updateTableData={updateTableData} 
        />
      </div>

      {showModal && (
        <div className="fixed">
          <div className="bg-white p-8 rounded-md">
            <ConnectionForm 
              closeModal={closeModal} 
              editMode={selectedConnectionData !== null}
              connectionData={selectedConnectionData}
              onAddConnection={handleAddConnection}
              onConnectionUpdated={updateTableData}
              updateTableData={updateTableData}
              rows={rows}
              setRows={setRows}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Connection;
