import React, { useState, useEffect } from 'react';
import close from '../Assets/close.svg';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const SMSModal = ({ isOpen, onClose, onSend, booking, responseMessage, setResponseMessage, companyDetails, errorMessage, setErrorMessage }) => {
  const [message, setMessage] = useState('');
  const fonts = {
    'serif': 'serif',
    'Helvetica': 'Helvetica',
    'monospace': 'monospace',
    'Comic Sans MS': 'Comic_Sans_MS',
  };

  const Font = Quill.import('formats/font');
  Font.whitelist = Object.values(fonts);
  Quill.register(Font, true);

  const modules = {
    toolbar: [
      [{ 'font': Object.keys(fonts) }],
      ['bold', 'italic', 'underline'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['clean']
    ]
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
  };  
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);

  // const formats = [
  //   'font',
  //   'bold', 'italic', 'underline',
  //   'list', 'bullet'
  // ];

  const stripHtml = (html) => {
    const temporalDivElement = document.createElement("div");
    temporalDivElement.innerHTML = html;
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
  };

  useEffect(() => {
    if (booking && companyDetails.length > 0) {
      const companyIndex = companyDetails.findIndex(detail =>
        detail.company === booking.company
      );

      if (companyIndex !== -1) {
        let bodyTemplate = companyDetails[companyIndex].Body;
        bodyTemplate = bodyTemplate.replace('{{code}}', `${booking.bookingCode}`);
        bodyTemplate = bodyTemplate.replace('{{booking id}}', `${booking.bookingId}`);
        bodyTemplate = bodyTemplate.replace('{{booking time}}', `${booking.bookingEndsAtTime}`);
        bodyTemplate = bodyTemplate.replace('{{booking end time}}', `${booking.bookingEndsAtTime}`);
        bodyTemplate = bodyTemplate.replace('{{booking start time}}', `${booking.bookingStartAtTime}`);
        bodyTemplate = bodyTemplate.replace('{{booking start date}}', `${booking.bookingStartAtDate}`);
        bodyTemplate = bodyTemplate.replace('{{booking end date}}', `${booking.bookingStartAtDate}`);
        bodyTemplate = bodyTemplate.replace('{{customer name}}', `${booking.bookingCustomerName}`);
        bodyTemplate = bodyTemplate.replace('{{company name}}', `${booking.company}`);
        bodyTemplate = bodyTemplate.replace('{{company id}}', `${booking.company_ID}`);
        setMessage(bodyTemplate);
      } else {
        setMessage('No company details available.');
      }
    }
  }, [booking, companyDetails]);

  if (!isOpen) {
    return null;
  }

  const handleSend = () => {
    let companyID;
    
    if (booking.company_ID === "5503221880" && booking.company === "Matland") {
      companyID = "1";
    } else if (booking.company_ID === "6104201920" && booking.company === "Golfstöðin") {
      companyID = "2";
    } else if (booking.company_ID.length > 3 && booking.company === "Matland") {
      companyID = "1"
    } else if (booking.company_ID.length > 3 && booking.company === "Golfstöðin") {
      companyID = "2"
    } else {
      companyID = booking.company_ID;
    }
    onSend(
      booking.bookingCustomerPhone,
      stripHtml(message),
      companyID,
      booking.userId
    );
  };

  return (
    <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
      <div className='w-4/4 ml-8 mr-10 md:w-2/5' style={{ backgroundColor: '#fff', padding: '20px', borderRadius: '0px', display: 'flex', flexDirection: 'column', color: '#344054' }}>
        <div className='flex flex-row justify-between mb-6'>
          <label htmlFor="messageText" style={{ fontSize: '24px', fontWeight: 'bold' }}>Message text</label>
          <img
            onClick={onClose}
            src={close}
            alt="User"
            className="cursor-pointer"
          />
        </div>
        {responseMessage && 
          <div className="bg-green-200 w-full text-green-800 p-2 mb-4">
            {responseMessage}
          </div>
        }

        {errorMessage && 
          <div className="bg-red-200 w-full text-red-800 p-2 mb-4">
            {errorMessage}
          </div>
        }
        {/* <ReactQuill
          theme="snow"
          value={message}
          onChange={setMessage}
          // modules={modules}
          // formats={formats}
          bounds={'.app'}
          placeholder="Compose an epic..."
        /> */}

        <textarea
          id="messageText"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Compose an epic..."
          style={{ width: '100%', height: '100px', padding: '10px', fontSize: '16px', border: '1px solid #ccc' }}
        />
        

        <div className='mt-5' style={{ display: 'flex', justifyContent: 'end' }}>
          <button
            onClick={() => { setResponseMessage(''); 
            onClose(); }}
            style={{ width: '100px', padding: '10px', margin: '5px', backgroundColor: 'transparent', border: '1px solid #ccc', color: '#344054' }}
          >
            Discard
          </button>
          <button
            onClick={handleSend}
            style={{ width: '100px', padding: '10px', margin: '5px', backgroundColor: 'black', color: 'white', border: 'none' }}
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default SMSModal;
